
.password {
  font-size: 12px;
  .content {
    padding-bottom: 50px;
    .closeBox {
      width: 100%;
      margin-top: 20px;
      text-align: right;
      margin-bottom: 20px;
      img {
        width: 20px;
        height: 20px;
        margin-right: 20px;
      }
    }
    .item {
      position: relative;
      width: 95%;
      margin: 0 auto;
      margin-bottom: 20px;
      .sendmsg {
        position: absolute;
        right: 12px;
        top: 12px;
        font-size: 14px;
        color: #00b897;
      }
    }
    .btn {
      width: 345px;
      height: 46px;
      background: #00b897;
      border-radius: 4px;
      margin: 0 auto;
      font-size: 18px;
      text-align: center;
      line-height: 46px;
      color: #ffffff;
    }
  }
}
::v-deep .van-cell {
  background-color: #f8f8f8;
}
