
.popupCertain3{
  padding: 10px;
  .van-tabs__wrap{
      height: auto;
  }
  .subTitle{
      padding-bottom: 10px;
      color: #a2a2a2;
      border-bottom: 1px solid #eee;
  }
  .tab1{
      margin: 10px 0;
      .van-tabs__nav--card{
          margin: 0;
          border-radius: 5px;
      }
  }
  .row{
      padding: 10px 0;
      .Nactive{
        position: relative;
          padding: 5px 10px;
          height: 33px;
          background: #f8f8f8;
          color: #000;
          margin-left: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          position: relative;
      }
      .active{
        position: relative;
          padding: 5px 10px;
          height: 33px;
          background: #f8f8f8;
          color: #10c9aa;
          margin-left: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          position: relative;
      }
      .right{
          text-align: right;
      }
  }
  .buttoncon{
    margin: 15px 0;
  }
  .describe{
    margin-top: 10px;
    padding: 5px;
    background-color: #f8f8f8;
    div{
      padding: 5px;
    }
  }
  .title{
    border-bottom: 1px solid #e3e5eb;
    margin-bottom: 10px;
    .titletop{
      color: #000;
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 5px;
    }
    .titlebottom{
      // font-size: 16px;
      color: #000;
      padding-bottom: 5px;
    }
  }
  .sub{
    padding-bottom: 10px;
    color: #a2a2a2;
    span{
      color: #00b897;
    }
  }
  .pper{
      margin: 10px 0;
  }
  .pperinput{
          background: #f8f8f8;
          border-radius: 5px;
      }
      .activeNum{
        margin: 0 0 10px 0;
    border: 1px solid #00b897;
    display: flex;
    div{
      flex: 1;
      height: 30px;
      border-right: 1px solid #00b897;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    div:last-child{
      border-right: none;
    }
    .active{
      color: #fff;
      background-color: #00b897;
    }
  }
}
