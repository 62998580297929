
::v-deep .trade {
  .van-cell::after {
    border-bottom: 0px;
  }
}

::v-deep .van-cell {
  padding: 5px 10px;
  background-color: transparent;
}

::v-deep .buyMoney {
  .van-cell {
    padding: 8px 10px;
  }
}

::v-deep .van-divider {
  margin: 0;
}

.van-cell::after {
  border-bottom: 0px;
}

.popopContont {
  width: 70vw;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 20px;
  text-align: center;

  .title {
    color: #000;
    font-size: 16px;
  }

  .conttant {
    color: #333;
    margin: 20px 0;
  }

  .btn {
    width: 100%;
    color: #fff;
    background-color: #00b897;
    padding: 8px 0;
    border-radius: 20px;
  }
}
