
.phoneOption {
  min-height: 100vh;
  width: 100vw;
  background-color: #f8f8f8;
  .navbar {
    position: fixed;
    width: 100vw;
    background-color: #ffffff;
    // /deep/ .van-ellipsis{
    //   color: #ffffff !important;
    // }

    // /deep/ .van-icon {
    //   color: #ffffff !important;
    // }
  }

  .commonhead {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 44px;
    line-height: 44px;
    font-size: 2.8vw;
    background: #ffffff;
    color: #191b27;
    text-align: center;
    border-bottom: solid 1px #ebebf0;
  }

  .commonhead-icon {
    width: 10px;
    margin-top: 14px;
    margin-left: 20px;
    float: left;
  }

  .commonhead-tit {
    font-size: 4.2vw;
    margin-left: -10vw;
  }

  .commonhead-user {
    position: absolute;
    width: 6.8vw;
    top: 1.8vw;
    right: 28px;
  }

  .navbar-handle {
    width: 18px;
  }

  .van-hairline--bottom::after {
    border-bottom-width: 0rem;
  }

  .main-content {
    width: 100vw;
    height: 100vh;
    // padding: 30px;
    display: flex;
    display: -webkit-flex; /* Safari */
    flex-direction: column;
  }

  .top-functional {
    width: 92vw;
    height: 160px;
    margin-left: 4vw;
    margin-top: 60px;
    border-radius: 4px;
    background-color: #ffffff;
  }

  .sec-functional {
    width: 92vw;
    height: 108px;
    margin-left: 4vw;
    border-radius: 4px;
    background-color: #ffffff;
  }

  .item-cell {
    height: 52.5px;
    background-color: transparent;
    padding: 14.5px;

    .receipt-icon {
      width: 14px;
      height: 14px;
      margin-top: 5px;
    }

    .receipt-title {
      color: #d5d5d5;
      font-size: 15px;
      margin-left: 13px;
    }

    .receipt-content {
      color: #d5d5d5;
      font-size: 15px;
      margin-left: 13px;
    }

    .item-title {
      color: #191b27;
    }

    .item-custom-bx {
      position: relative;
      display: inline-block;
      float: right;
      color: #9299a9;
    }

    .ic-notice {
      left: -20px;
      top: 4px;
      width: 16px;
      height: 16px;
      position: absolute;
    }

    .van-cell__value {
      color: #9299a9;
    }
  }

  .item-cell.top-cell {
    height: 70px;
  }

  .tip-txt {
    margin: 12px 15px;
    font-size: 12px;
    color: #5c6573;
  }

  .item-cell::after {
    border-bottom: 1.5px solid #ebebf0;
  }

  .cell-level {
    width: 100%;

    .level-bx {
      width: 100%;
    }

    .level-tit {
      display: inline-block;
      font-size: 15px;
    }

    .level-tip {
      display: inline-block;
      font-size: 12px;
      color: #00b897;
      float: right;
    }

    .level-bar {
      display: inline-block;
      width: 98.33px;
      height: 4px;
      background-color: #d5d5d5;
    }

    .level-bar.selected {
      background-color: #00b897;
    }

    .mg-lft {
      margin-left: 10px;
    }
  }
}

// /deep/ {
//   /* 滚动条隐藏 */
//   ::-webkit-scrollbar {
//     width: 0px;
//   }
// }
