
.lockup {
  font-size: 12px;
  background-color: #f8f8fb;

  .topBox {
    width: 100%;
    height: 100px;
    // background: linear-gradient(315deg, #e9ba6c 0%, #f4dab0 100%);
    background: linear-gradient(90deg, #00b897 0%, #00b897 100%);
    display: flex;
    padding: 0 26px;
    align-items: center;
    justify-content: space-between;

    .leftBox {
      font-size: 13px;
      color: #fff;
      line-height: 19px;

      .moneyBox {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        color: #fff;

        .moneyNum {
          font-size: 30px;
          line-height: 36px;
        }

        .moneyBoin {
          font-size: 13px;
          line-height: 19px;
        }
      }
    }

    .rightBox {
      font-size: 13px;
      font-weight: 600;
      color: #fff;
      line-height: 30px;
      height: 30px;
      border-radius: 15px;
      padding: 0 16px;
      border: 1px solid #fff;
    }
  }

  .contant {
    width: 100%;
    //height: 78px;
    // background: linear-gradient(90deg, #f4dba8 0%, #e5bf83 100%);
    background: linear-gradient(90deg, #00b897 0%, #00b897 100%);
    padding: 0 15px;
    display: flex;

    .itemBox {
      flex: 1;
      text-align: center;

      .num {
        font-size: 21px;
        font-weight: 600;
        color: #fff;
        line-height: 30px;
        padding-top: 15px;
      }

      .tips {
        font-size: 12px;
        font-weight: 400;
        color: #fff;
        line-height: 17px;
        margin-top: 2px;
      }
    }
  }

  .listBox {
    padding: 13px 15px;

    .listItem {
      width: 100%;
      height: 110px;
      background: #ffffff;
      border-radius: 5px;
      padding: 15px 10px;
      margin-bottom: 12px;

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .name {
          font-size: 15px;
          font-weight: 600;
          color: #131520;
          line-height: 21px;
        }

        .btn {
          width: 77px;
          height: 30px;
          background: #00b897;
          border-radius: 4px;
          color: #fff;
          line-height: 30px;
          text-align: center;
        }
      }

      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        .contantItem {
          .tit {
            font-size: 12px;
            font-weight: 400;
            color: #9299a9;
            line-height: 17px;
          }

          .num {
            font-size: 13px;
            font-weight: 600;
            color: #131520;
            line-height: 19px;
            margin-top: 5px;
          }
        }

        .left {
          text-align: left;
        }

        .center {
          text-align: center;
        }

        .right {
          text-align: right;
        }
      }
    }
  }

  .buyBox {
    padding: 19px 15px;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .close {
        width: 22px;
        height: 22px;
      }

      .tit {
        font-size: 18px;
        font-weight: 600;
        color: #191b27;
        line-height: 25px;
      }
    }

    .heng {
      width: 100%;
      height: 1px;
      background-color: #ebebf0;
      margin-top: 9px;
    }

    .contantBox {
      display: flex;

      .item {
        flex: 1;
        text-align: left;
        margin-top: 13px;

        .tit {
          color: #9299a9;
          line-height: 17px;
          font-size: 12px;
        }

        .num {
          font-weight: 600;
          font-size: 13px;
          color: #131520;
          line-height: 19px;
          margin-top: 5px;
        }
      }
    }

    .inputBox {
      position: relative;
      margin-top: 18px;

      .box {
        border: 1px solid #9299a9;
        border-radius: 4px;
      }

      .name {
        position: absolute;
        right: 11px;
        top: 11px;
        font-size: 15px;
        color: #191b27;
      }
    }

    .userMoney {
      font-size: 12px;
      color: #9299a9;
      margin-top: 5px;
    }

    .sub {
      width: 100%;
      height: 46px;
      background: #00b897;
      border-radius: 4px;
      color: #fff;
      text-align: center;
      line-height: 46px;
      margin-top: 25px;
      font-size: 18px;
      font-weight: 600;
    }
  }
}

::v-deep .van-nav-bar__right .van-nav-bar__text {
  color: #5c6573;
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}
