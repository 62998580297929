
.guide {
  background-color: #f7f7f7;
  .content {
    padding: 18px;
    h1 {
      font-size: 20px;
      color: #211f1f;
      line-height: 40px;
    }
    p {
      color: #949494;
      margin-bottom: 20px;
    }
    img {
      margin-bottom: 8px;
    }
  }
}
::v-deep .van-nav-bar .van-icon {
  color: #000;
}
