
.selectKlinePopup {
  position: absolute;
  top: 160px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 99;
  .timeBox {
    width: 100%;
    background-color: #fff;
    //height: 45px;
    display: flex;
    justify-content: space-around;
    border-top: #f4f4f6;
    padding-top: 10px;
    flex-wrap: wrap;
    .item {
      padding: 0px 15px;
      background-color: #f4f4f6;
      height: 26px;
      line-height: 24px;
      font-size: 12px;
      text-align: center;
      border-radius: 3px;
      border: 1px solid #f4f4f6;
      margin-bottom: 10px;
    }
    .itemActive {
      border: 1px solid #00b897;
      background-color: #fff;
      color: #00b897;
    }
  }
  .klineTypeBox {
    width: 100%;
    background-color: #fff;
    height: 110px;
    padding-top: 15px;
    .box {
      text-align: center;
      align-items: center;
      .typeName {
        width: 80px;
      }
      display: flex;
      .typeList {
        line-height: 24px;
        display: flex;
        .item {
          width: 56px;
          background-color: #f4f4f6;
          height: 26px;
          line-height: 24px;
          font-size: 12px;
          text-align: center;
          border-radius: 3px;
          border: 1px solid #f4f4f6;
          margin-right: 15px;
        }
        .itemActive {
          border: 1px solid #00b897;
          background-color: #fff;
          color: #00b897;
        }
      }
    }
  }
}
.optionHome {
  display: flex;
  margin-top: 16px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  .item {
    display: flex;
    height: 17px;
    line-height: 17px;
    align-items: center;
    padding: 0 4px;
  }
  .itemActive {
    color: #00b897;
  }
  .more {
    width: 16px;
    height: 13px;
  }
  .jiantou {
    width: 9px;
    height: 5px;
    margin-left: 3px;
  }
}
/*k线部分*/
.blockBg {
  background: #fff;
  margin-top: 5px;
}
* {
  font-size: 12px;
}
.tabsTitle {
  height: 28px;
  line-height: 28px;
  border-bottom: 1px solid #ececec;
  border-top: 1px solid #ececec;
  padding: 0 4%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  font-size: 12px;
}
.tabsTitle span {
  width: 15%;
  height: 27px;
  line-height: 27px;
  border-bottom: 2px solid transparent;
  display: inline-block;
  text-align: center;
  vertical-align: top;
  font-size: 12px;
}
.tabsTitle span.active {
  border-bottom-color: #217cd9;
  color: #217cd9;
}
.klineWrap {
  float: left;
  position: relative;
  overflow: hidden;
}
.kLineTabs .tabsTitle {
  padding-left: 2%;
}
.kLineTabs .tabsTitle span {
  font-size: 12px;
  width: 16%;
}
.kLineTabs .tabsTitle span:last-child {
  width: 18%;
}
.indexWrap {
  width: 100%;
  height: 30px;
  background-color: #f6fbfe;
  font-size: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}
.indexWrap .indexItem {
  display: block;
  line-height: 30px;
  flex-grow: 1;
  text-align: center;
}
.indexWrap .indexItem.active {
  color: #333;
  background-color: #e1ecf2;
}
.highcharts-credits {
  display: none;
}
#technical-indicator-k-line {
  height: 500px;
}
#deepBox {
  // height: 500px;
}
