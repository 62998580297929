
.Card {
  font-size: 12px;
  .listItem {
    width: 100%;
    background: #ffffff;
    border-radius: 5px;
    padding: 15px 10px;
    margin-bottom: 12px;
    line-height: 25px;
    .box {
      display: flex;
      flex-wrap: wrap;
      div {
        width: 50%;
      }
    }
    .name {
      color: #9299a9;
    }
    .value {
      color: #191b27;
    }
    .closeBox {
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        align-items: center;
        img {
          width: 13px;
          height: 13px;
          margin-right: 5px;
        }
      }
      .closeBtn {
        padding: 0 20px;
        height: 30px;
        background: #00b897;
        border-radius: 4px;
        color: #fff;
        text-align: center;
        line-height: 30px;
      }
    }
    .over {
      width: 260px;
      height: 30px;
      background: rgba(0, 184, 151, 0.5);
      border-radius: 4px;
      color: #fff;
      line-height: 30px;
      text-align: center;
      margin: 0 auto;
      margin-top: 10px;
    }
  }
}
::v-deep .van-dialog__content {
  padding: 20px 20px;
}
::v-deep .van-dialog__confirm,
.van-dialog__confirm:active {
  background-color: #00b897;
}
