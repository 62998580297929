
.IEOlog {
  font-size: 12px;
  background-color: #fff;
  .dateBox {
    width: 100%;
    height: 50px;
    background-color: #f8f8fb;
    display: flex;
    align-items: center;
    padding: 0 15px;
    position: relative;
    img {
      width: 13px;
      height: 13px;
      margin-right: 10px;
    }
    .time {
      color: #9299a9;
    }
    .zhi {
      margin: 0 15px;
    }
    .select {
      position: absolute;
      right: 15px;
      top: 10px;
      width: auto;
      height: 30px;
      color: #fff;
      background: #00b897;
      padding: 0 12px;
      line-height: 30px;
      text-align: center;
      border-radius: 4px;
    }
  }
  .listBox {
    padding: 12px 15px;
    background-color: #fff;
    .listItem {
      color: #000;
      font-size: 13px;
      line-height: 29px;
      .itemLine {
        display: flex;
        span {
          color: #9299a9;
        }
        .left {
          flex: 0.9;
        }
        .right {
          flex: 1;
        }
      }
      .line {
        width: 100%;
        height: 1px;
        background-color: #ebebf0;
      }
      .err {
        color: #00b897;
      }
      .tips {
        color: #00b897;
      }
      .title {
        justify-content: space-between;
        .left {
          font-weight: 600;
        }
        .right {
          color: #5c6573;
          text-align: right;
        }
      }
    }
  }
}

::v-deep .van-popover__wrapper {
  display: flex;
  align-items: center;
  margin-left: 5px;
  img {
    height: 14px;
    width: 14px;
  }
}
::v-deep .van-nav-bar .van-icon {
  color: #000;
}
