
.box{
  padding: 10px;
  .van-row{
    .margin{
      span{
        margin-right: 10px;
      }
    }
    margin-bottom: 10px;
    span{
      color: #5C6573;
    }
    .Sub{
      color: #000;
      word-wrap: break-word;
    }
    .Sub2{
      color: #00b897;
    }
    .title{
      font-size: 16px;
    }
    .left{text-align: left;}
    .right{text-align: right;}
    .center{text-align: center;}
    .red{
      color: #e54d68;
    }
    .gre{
      color: #1baf84;
    }
  }
}
.Subset{
  position: relative;
  .SubsetBarSub{
    color:#5C6573;
    // font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 44px;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.imgNoData {
  width: 170px;
  height: 105px;
  display: block;
  margin: 0 auto;
  margin-top: 20px;
}

.textNoData {
  text-align: center;
  line-height: 100px;
}

.tradeList {
  margin-top: 8px;
  width: 100%;
  background-color: #fff;
  .titItem {
    font-size: 16px;
    font-weight: 600;
  }

  .recentlyBox {
    padding: 15px;

    .titBox {
      display: flex;
    }

    .listBox {
      margin-top: 11px;

      .itemBox {
        display: flex;
        margin-bottom: 15px;

        .buy {
          color: #18ae83;
        }

        .sell {
          color: #ED0070;
        }
      }
    }

    .item {
      flex: 1;
      font-size: 13px;
      color: #9299a9;
      line-height: 19px;
    }

    .item1 {
      text-align: left;
      color: #000;
    }

    .item2 {
      text-align: center;
    }

    .item3 {
      color: #000;
      text-align: right;
    }
  }

  .nowEntrust {
    .btnBox {
      width: 100%;
      background-color: #f8f8fb;
      display: flex;
      padding: 0 15px;
      line-height: 40px;
      height: 40px;
      color: #9299a9;
      justify-content: space-between;

      .allOrder {
        display: flex;
        align-items: center;

        span {
          margin-left: 8px;
        }
      }
    }

    .orderListBox {
      width: 100%;
      padding: 0 15px;
      background-color: #fff;

      .orderList {
        width: 100%;

        .item {
          width: 100%;
          margin-top: 15px;

          .top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .tit {
              font-size: 16px;
              font-weight: 600;
              color: #191b27;
              line-height: 23px;
            }

            .cexiao {
              width: 16px;
              height: 16px;
            }
          }

          .mine {
            display: flex;
            align-items: center;
            margin-top: 10px;

            .type {
              // width: 36px;
              height: 20px;
              background: rgba(255, 96, 96, 0.1);
              border-radius: 2px;
              text-align: center;
              font-size: 12px;
              font-weight: 400;
              color: #ED0070;
              line-height: 20px;
            }

            .type2 {
              // width: 36px;
              height: 20px;
              background: rgba(255, 96, 96, 0.1);
              border-radius: 2px;
              text-align: center;
              font-size: 12px;
              font-weight: 400;
              color: #ED0070;
              line-height: 20px;
              background: #d6ede5 !important;
              color: #18ae83;
            }

            .progress {
              width: 100px;
              height: 4px;
              background: rgba(199, 204, 216, 0.2);
              border-radius: 2px;
              margin-left: 20px;
            }

            .num {
              margin-left: 10px;
            }
          }

          .bottom {
            display: flex;
            margin-top: 15px;

            .item {
              flex: 1;
              line-height: 19px;
              font-size: 13px;
              margin-top: 0;
              padding-bottom: 15px;
              border-bottom: 1px solid #ebebf0;

              .tit {
                font-weight: 400;
                color: #9299a9;
              }

              .content {
                font-weight: 600;
                color: #191b27;
                margin-top: 5px;
                white-space: nowrap;
                font-size: 10px;
              }
            }

            .item1 {
              text-align: left;
            }

            .item2 {
              text-align: center;
            }

            .item3 {
              text-align: right;
            }
          }
        }
      }
    }
  }
}

.reverseContent {
  padding: 0 15px;
  padding-bottom: 30px;

  .fanShouTitle {
    margin-top: 16px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  }

  .fanShouSamallTips {
    margin-top: 12px;
    display: flex;
    font-size: 12px;
    line-height: 20px;

    .biaoQian {
      height: 20px;
      background: #F8F8F8;
      border-radius: 3px 3px 3px 3px;
      padding: 0px 6px;
      color: #888888;
    }

    .fangXiang {
      margin-left: 15px;
    }
  }

  .nowContent {
    margin-top: 11px;
    padding: 10px 12px;
    background: rgba(188, 188, 188, 0.1);
    margin-bottom: 12px;

    .nowContentTitle {
      display: flex;

      .biaoQian {
        height: 20px;
        border-radius: 3px 3px 3px 3px;
        padding: 0px 6px;
      }

      .beiShu {
        margin-left: 15px;
        font-weight: 400;
      }


      .biaoQianSell {
        background: #F6E7E9;
        color: #E54C67;
      }

      .biaoQianBuy {
        background: #DFF2EE;
        color: #00B897;
      }
    }

    .nowContentData {
      display: flex;
      margin-top: 16px;
      padding-bottom: 15px;

      .dataBox {
        flex: 1;
        text-align: center;

        .tit {
          color: #888888;
          line-height: 14px;
        }

        .data {
          color: #000000;
          line-height: 21px;
        }
      }
    }
  }
}
::v-deep .van-tab__text--ellipsis {
  -webkit-box-orient: horizontal;
}

::v-deep .van-action-sheet__header {
  text-align: left;
  padding-left: 15px;
  font-size: 18px;
  font-weight: 800;
}
::v-deep .van-tab__text--ellipsis {
  -webkit-box-orient: horizontal;
}
::v-deep .van-tabs__wrap {
  border-bottom: 1px solid #f8f8fb;
}

::v-deep .van-action-sheet {
  max-height: 70% !important;
}
