
.main {
  padding: 30px 20px 0;
  .tit {
    font-weight: 600;
  }
  .cell {
    margin-top: 15px;
    > span {
      display: inline-block;
      font-size: 14px;
      color: #888888;
    }

    .ellipsis {
      width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
.main_b {
  .cell {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > span {
      flex: 2;
    }
    > span:first-child {
      flex: 1;
      width: 110px;
    }
    .ellipsis {
      width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
.main_t {
  .cell {
    display: block;
    font-size: 13px;
    .tradeDesc {
      color: #00b897;
    }
    > span:first-child {
      margin-right: 10px;
    }
  }
}
.icon_copy {
  width: 17px;
  height: 17px;
}
