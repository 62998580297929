
.authenticationVideo {
  padding-top: 44px;
  background-color: #f8f8f8;
  min-height: 100vh;
  padding-bottom: 40px;
}

.commonhead {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 44px;
  line-height: 44px;
  font-size: 2.8vw;
  background: #ffffff;
  color: #191b27;
  text-align: center;
  border-bottom: solid 1px #ebebf0;
}

.commonhead-icon {
  width: 10px;
  margin-top: 14px;
  margin-left: 20px;
  float: left;
}

.commonhead-tit {
  font-size: 4.2vw;
  margin-left: -10vw;
}

.commonhead-user {
  position: absolute;
  width: 6.8vw;
  top: 1.8vw;
  right: 28px;
}

.auth-title {
  color: #5c6573;
  font-size: 14px;
  margin: 15px;
}

.auth-title-tip {
  margin: 15px;
  font-size: 12px;
  color: #00b897;
}

.auth-title p {
  font-size: 12px;
}

.auth-title .mgt12 {
  margin-top: 12px;
}

.loading-img {
  margin-left: 10%;
  margin-top: 32vw;
  width: 80vw;
}

.loading-sp {
  position: absolute;
  width: 30vw;
  top: 50vw;
  left: 50%;
  margin-left: -15vw;
}

.custom-image .van-empty__image {
  width: 120px;
  height: 120px;
}

.idcard-box {
  width: 345px;
  height: 202px;
  background-color: #ffffff;
  border-radius: 4px;
  margin-left: 15px;
}

.idcard-f {
  width: 248px;
  height: 152px;
  margin: 20px 48.5px;
}

.upload-tip {
  width: 100%;
  text-align: center;
  color: #00b897;
  font-size: 14px;
  margin-top: -10px;
  padding: 0;
}

.bottom-sample {
  margin-left: 5px;
}

.s-box {
  position: relative;
  display: inline-block;
  width: 78.5px;
  margin-left: 10px;
}

.sp-img {
  width: 78.5px;
}

.sp-txt {
  display: block;
  width: 78.5px;
  font-size: 13px;
  color: #9299a9;
  text-align: center;
}

.r-content {
  color: #00b897;
}

.btn-commit {
  width: 345px;
  height: 46px;
  margin-left: 15px;
  margin-top: 20px;
}
