
.imgNoData {
  width: 170px;
  height: 105px;
  display: block;
  margin: 0 auto;
  margin-top: 20px;
}
.textNoData {
  text-align: center;
  line-height: 100px;
}
.tradeList {
  margin-top: 8px;
  width: 100%;
  background-color: #fff;
  .titItem {
    font-size: 16px;
    font-weight: 600;
  }
  .recentlyBox {
    padding: 15px;
    .titBox {
      display: flex;
    }
    .listBox {
      margin-top: 11px;
      .itemBox {
        display: flex;
        margin-bottom: 15px;
        .buy {
          color: #0AA869;
        }
        .sell {
          color: #ED0071;
        }
      }
    }
    .item {
      flex: 1;
      font-size: 13px;
      color: #9299a9;
      line-height: 19px;
    }
    .item1 {
      text-align: left;
      color: #000;
    }
    .item2 {
      text-align: center;
    }
    .item3 {
      color: #000;
      text-align: right;
    }
  }
  .nowEntrust {
    .btnBox {
      width: 100%;
      background-color: #f8f8fb;
      display: flex;
      padding: 0 15px;
      line-height: 40px;
      height: 40px;
      color: #9299a9;
      justify-content: space-between;
      .allOrder {
        display: flex;
        align-items: center;
        span {
          margin-left: 8px;
        }
      }
    }
    .orderListBox {
      width: 100%;
      padding: 0 15px;
      background-color: #fff;
      .orderList {
        width: 100%;
        .item {
          width: 100%;
          margin-top: 15px;
          .top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .tit {
              font-size: 16px;
              font-weight: 600;
              color: #191b27;
              line-height: 23px;
            }
            .cexiao {
              width: 16px;
              height: 16px;
            }
          }
          .mine {
            display: flex;
            align-items: center;
            margin-top: 10px;
            .type {
              // width: 36px;
              height: 20px;
              background: rgba(255, 96, 96, 0.1);
              border-radius: 2px;
              text-align: center;
              font-size: 12px;
              font-weight: 400;
              color: #ED0071;
              line-height: 20px;
            }
            .type2 {
              // width: 36px;
              height: 20px;
              background: rgba(255, 96, 96, 0.1);
              border-radius: 2px;
              text-align: center;
              font-size: 12px;
              font-weight: 400;
              color: #ED0071;
              line-height: 20px;
              background: #d6ede5 !important;
              color: #0AA869;
            }
            .progress {
              width: 100px;
              height: 4px;
              background: rgba(199, 204, 216, 0.2);
              border-radius: 2px;
              margin-left: 20px;
            }
            .num {
              margin-left: 10px;
            }
          }
          .bottom {
            display: flex;
            margin-top: 15px;
            .item {
              flex: 1;
              line-height: 19px;
              font-size: 13px;
              margin-top: 0;
              padding-bottom: 15px;
              border-bottom: 1px solid #ebebf0;
              .tit {
                font-weight: 400;
                color: #9299a9;
              }
              .content {
                font-weight: 600;
                color: #191b27;
                margin-top: 5px;
                white-space: nowrap;
                font-size: 10px;
              }
            }
            .item1 {
              text-align: left;
            }
            .item2 {
              text-align: center;
            }
            .item3 {
              text-align: right;
            }
          }
        }
      }
    }
  }
}

::v-deep .van-tabs__wrap {
  border-bottom: 1px solid #f8f8fb;
}
