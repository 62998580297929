
.Details {
  img {
    width: 100% !important;
  }

  font-size: 12px;
  background-color: #f8f8fb;
  height: 100%;
  .content {
    padding: 12px 15px;
    .topbox {
      padding: 12px 10px;
      background-color: #fff;
      border-radius: 4px;
      .title {
        font-size: 18px;
        font-weight: 600;
        color: #00b897;
        line-height: 25px;
        margin-bottom: 10px;
      }
      .smallTit {
        font-size: 13px;
        color: #9299a9;
        line-height: 20px;
        span {
          color: #000;
        }
      }
      .timeBox {
        display: flex;
        justify-content: space-between;
        text-align: center;
        margin-top: 5px;
        .timeItem {
          width: 44px;
          .topNum {
            width: 100%;
            height: 40px;
            // background: #faf4e9;
            background: linear-gradient(90deg, #00b897 0%, #00b897 100%);
            border-radius: 4px;
            color: #fff;
            text-align: center;
            font-size: 16px;
            line-height: 40px;
          }
          .text {
            line-height: 35px;
            color: #9299a9;
          }
        }
      }
      .progress {
        margin-top: 12px;
      }
      .overtime {
        margin-top: 20px;
      }
    }
    .timeDetalse {
      padding: 8px 10px;
      background-color: #fff;
      border-radius: 4px;
      margin-top: 12px;
      font-size: 13px;
      line-height: 34px;
      .item {
        display: flex;
        .itmeName {
          color: #9299a9;
        }
      }
    }

    .btnBox {
      width: 100%;
      background-color: #fff;
      padding: 20px 10px;
      margin-top: 20px;
      border-radius: 5px;
      .btn {
        width: 260px;
        height: 46px;
        background-color: #c7ccd8;
        line-height: 46px;
        text-align: center;
        margin: 0 auto;
        border-radius: 4px;
        font-weight: 500;
        font-size: 18px;
        color: #ffffff;
        margin-top: 30px;
      }
      .now {
        background-color: #00b897;
        color: #fff;
      }
      .buyBox {
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: relative;
        padding-bottom: 26px;
        .buyInput {
          border: 1px solid #c7ccd8;
          border-radius: 4px;
        }
        .balanceBox {
          position: absolute;
          right: 14px;
          bottom: 0;
          display: flex;
          height: 17px;
          align-items: flex-end;

          .text {
            margin-left: 5px;
            span {
              color: #00b897;
            }
          }
        }
      }
    }
    .conditions {
      margin-top: 20px;
      .tit {
        font-size: 15px;
        font-weight: 600;
        color: #000;
      }
      .listBox {
        margin-top: 5px;
        margin-left: 15px;
        color: #9299a9;
        line-height: 19px;
      }
    }
    .bottomTips {
      color: #00b897;
      margin-top: 20px;
    }
  }
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}
::v-deep .van-cell--center {
  color: #000;
  border-radius: 4px;
}
::v-deep .van-popover__wrapper {
  width: 14px;
  height: 14px;
}
