
.Card {
  font-size: 12px;
  .listItem {
    background-color: #fff;
    border-radius: 5px;
    padding: 10px 10px;
    line-height: 24px;
    margin-bottom: 15px;
    .itemTitle {
      font-weight: 600;
    }
    .line1 {
      display: flex;
      .itemTotal {
        flex: 1.2;
      }
      .itemOver {
        flex: 1;
      }
    }

    .timeBox {
      line-height: 36px;
      display: flex;
      justify-content: space-between;
      position: relative;
      .timeIcon {
        position: absolute;
        left: 0;
        bottom: 11px;
      }
      .time {
        margin-left: 20px;
        color: #00b897;
      }
      .btn {
        width: 90px;
        height: 30px;
        background: #00b897;
        border-radius: 4px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        white-space: pre-wrap;
        overflow-wrap: break-word;
        line-height: 14px;
        display: flex;
        align-items: center;
      }
    }
  }
}
