
.BOX {
  background: #f8f8fb;
  width: 345px;
  margin: 0 auto;
  margin-top: 15px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 40px;
  padding: 15px;
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      color: #9299a9;
    }
    .right {
      color: #191b27;
    }
  }
}
.contant {
  padding: 15px;
  border-bottom: 1px solid #ebebf0;
}
.rightimg {
  display: flex;
  align-items: center;
  img {
    margin-left: 5px;
  }
}
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .right {
    font-size: 12px;
    display: flex;
    align-items: center;
    img {
      width: 6px;
      height: 10px;
    }
    .rightText {
      color: #00b897;
    }
  }

  .left {
    display: flex;
    align-items: center;
  }
  .duiming {
    font-size: 16px;
    font-weight: 600;
    color: #191b27;
    margin-left: 15px;
  }
  .type {
    // width: 36px;
    height: 20px;
    background: rgba(255, 96, 96, 0.1);
    border-radius: 2px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #ED0071;
    line-height: 20px;
    padding: 0 4px;
  }
  .type2 {
    // width: 36px;
    height: 20px;
    background: rgba(255, 96, 96, 0.1);
    border-radius: 2px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #ED0071;
    line-height: 20px;
    background: #d6ede5 !important;
    color: #0AA869;
    padding: 0 4px;
  }
}
.bottom {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
  align-items: center;
  .itembottom {
    line-height: 19px;
    font-size: 13px;
    margin-top: 0;
    word-break: break-all;
    white-space: normal;
    padding: 0 2px;
    .tit {
      font-weight: 400;
      color: #9299a9;
    }
    .content {
      color: #191b27;
      margin-top: 5px;
      white-space: nowrap;
      font-size: 10px;
    }
  }
  .item1 {
    text-align: left;
  }
  .item2 {
    text-align: center;
  }
  .item3 {
    text-align: right;
  }
}
