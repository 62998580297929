
.deep {
  width: 100vw;
  // height: 500px;
  position: relative;
  .titBox {
    position: absolute;
    top: 20px;
    display: flex;
    left: 50%;
    transform: translateX(-50%);
    .item {
      height: 14px;
      line-height: 14px;
      display: flex;
      align-items: center;
      .dian {
        width: 6px;
        height: 6px;
        border-radius: 50%;
      }
      .dian1 {
        background: #0AA869;
      }
      .dian2 {
        background: #ED0071;
      }
      .text {
        margin-left: 5px;
      }
    }
    .item1 {
      margin-left: 16px;
    }
  }
}
#deepDiv {
  width: 100vw;
  height: 400px;
}
