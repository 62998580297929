
::v-deep a {
  color: #0000ee !important;
}
.newsContent {
  .box {
    padding: 15px;
    color: #5c6573;
    font-size: 14px;
    img {
      max-width: 100%;
    }
  }
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}
