
.setPassword {
  font-size: 12px;
}

.content {
  height: 100vh;
  width: 100%;
  background: #f8f7fc;
  padding: 15px;

  .shenfenBox {
    width: 100%;
    border-radius: 5px;
    background: #00b897;
    height: 122px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 10px;
    padding-right: 10px;

    .touxiang {
      width: 58px;
      height: 58px;
      margin-left: 15px;
    }

    .textBox {
      margin-left: 28px;
    }
  }

  .shenfenBox1 {
    background: #fff;
  }
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}
