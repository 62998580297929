
.ADPage {
  font-size: 12px;
  background-color: #f7e5cb;
  padding-bottom: 100px;
  .guding {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-image: url('../../assets/img/IEO/bg.png');
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 99;
  }

  .backImg {
    position: absolute;
    left: 15px;
    top: 11px;
    width: 10px;
  }
  .name {
    position: absolute;
    left: 44px;
    top: 36px;
    color: #9b3215;
    font-size: 24px;
    font-weight: 600;
    line-height: 39px;
  }
  .new {
    position: absolute;
    left: 44px;
    top: 67px;
    font-weight: 600;
    color: #f94d31;
    font-size: 22px;
    line-height: 45px;
  }
  .contant {
    padding: 0 24px;
    padding-top: 141px;
  }
  .coinBox {
    width: 325px;
    background: #fbf3e9;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 13px 13px 13px 13px;
    padding-top: 23px;
    padding-bottom: 4px;
    .topBox {
      display: flex;
      justify-content: space-evenly;
      .surfce {
        pointer-events: none;
        width: 119px;
        height: 119px;
        transform: rotate(145deg);
      }
      .nowPlan {
        .coinName {
          text-align: right;
          font-size: 23px;
          font-weight: 600;
          color: #9b3215;
          line-height: 27px;
        }
        .planBOx {
          margin-top: 32px;
          .planItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;
            .yuan {
              width: 12px;
              height: 12px;
              background: #d9d9d9
                linear-gradient(260deg, #ffbbad 0%, #f63d2a 100%);
              border-radius: 50%;
            }
            .plantype {
              font-size: 10px;
              font-weight: 400;
              color: #424242;
              line-height: 14px;
              margin-left: 7px;
              width: 60px;
              text-align: left;
            }
            .planNum {
              margin-left: 5px;
              font-size: 16px;
              font-weight: 600;
              color: #424242;
              line-height: 18px;
              text-align: right;
              width: 60px;
            }
          }
          .planItem1 {
            .yuan {
              background: #d9d9d9
                linear-gradient(266deg, #ffefe3 0%, #ffb038 100%);
            }
          }
        }
      }
    }
    .line {
      width: 292px;
      height: 1px;
      background-color: rgba(231, 160, 75, 0.14);
      margin: 0 auto;
      margin-top: 5px;
    }
    .bottom {
      width: 100%;
      padding: 0 20px;
      padding-top: 12px;
      .item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        white-space: nowrap;
        .left {
          font-size: 12px;
          font-weight: 600;
          color: #000000;
          line-height: 22px;
        }
        .right {
          height: 24px;
          font-size: 12px;
          line-height: 12px;
          text-align: right;
          span {
            font-size: 18px;
            font-weight: 600;
            color: #f94a30;
            line-height: 18px;
          }
        }
      }
    }
  }
  .coinContant {
    width: 325px;
    border-radius: 13px;
    margin: 0 auto;
    margin-top: 435px;
    position: relative;
    padding: 30px 15px 15px 15px;
    .topBar {
      position: absolute;
      height: 36px;
      top: -23px;
      left: 15px;
      background-size: 100% 100%;
      line-height: 36px;
      font-size: 16px;
      text-align: center;
      font-weight: 600;
      color: #9b3215;
    }
    .text {
      font-size: 14px;
      color: #424242;
      line-height: 22px;
    }
  }
  .bottomBtn {
    position: fixed;
    bottom: 15px;
    left: 0;
    width: 100%;
    height: 74px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 68px;
    color: #451a0e;
    background-image: url('../../assets/img/IEO/bottomBtn.png');
    background-size: 100% 100%;
  }
}
