
.setPassword {
  font-size: 12px;
  background-color: #f8f8f8;
  .content {
    padding: 12px;
    border-radius: 8px;
    .van-cell-group {
      border-radius: 10px;
    }
  }
  .tips {
    margin-top: 20px;
    color: #5c6573;
  }
  .next {
    width: 345px;
    height: 46px;
    background: #00b897;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    line-height: 46px;
    margin: 0 auto;
    margin-top: 30px;
  }
  .jiantou {
    width: 7px;
    height: 4px;
    margin-left: 4px;
  }

  .country {
    display: flex;
    min-width: 55px;
    align-items: center;
  }
}

.countriesListBox {
  .listItem {
    font-size: 14px;
    line-height: 54px;
    margin: 0 19px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
  }
}
::v-deep .van-nav-bar .van-icon {
  color: #000;
}
::v-deep .van-cell::after {
  border: 0;
}
