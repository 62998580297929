
.Incitation {
  font-size: 12px;
}
.topbox {
  width: 345px;
  height: 138px;
  // background: linear-gradient(315deg, #e9ba6c 0%, #f4dab0 100%);
  background: linear-gradient(90deg, #00b897 0%, #00b897 100%);
  border-radius: 5px;
  margin: 10px auto;
  box-sizing: border-box;
  padding: 0 15px;
  .top {
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    color: #fff;
    height: 60px;
    align-items: center;
    .content {
      display: flex;
      align-items: center;
      .text {
        font-size: 16px;
      }
      .num {
        font-size: 21px;
        font-weight: 600;
        margin-left: 10px;
      }
    }
    .copy {
      width: 15px;
      height: 15px;
    }
  }
}

.shareBtn {
  position: fixed;
  bottom: 20px;
  width: 88%;
  height: 46px;
  background: #00b897;
  border-radius: 4px;
  color: #fff;
  line-height: 46px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);

  a {
    color: #fff;
  }
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}
