
.van-dialog {
  width: 270px;
  background: transparent;
  font-family: PingFangSC-Regular, PingFang SC;
  border-radius: 7px;
  // border: 1px solid #2d303d;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
::v-deep {
  .van-dialog__header {
    padding-top: 20px;
    font-size: 18px;
    color: #000000;
    background: #fff;
    border: 0;
  }
  .van-dialog__content {
    flex: 1;
    min-height: 50px;
    font-size: 15px;
    background: #fff;
    .van-dialog__message {
      padding: 10px 0;
      font-size: 15px;
    }
    .message {
      flex: 1;
      text-align: center;
      min-height: 45px;
      font-size: 15px;
      margin: 10px 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .van-dialog__footer {
    display: none;
    height: 50px;
    background: #00b897;

    .van-dialog__confirm {
      height: 50px;
      background: #00b897;
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
    }
  }
}
.title {
  padding-top: 20px;
  font-size: 18px;
  text-align: center;
  color: #000000;
  background: #fff;
}
.footer_button {
  .button {
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #00b897;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
  }
}
