
.list {
  height: 100%;
  padding: 10px;
  .item {
    width: 100%;
    border-radius: 3px;
    // border-bottom: 1px solid #ebebf0;
    margin-bottom: 10px;
    padding: 0 10px 10px;
    display: flex;
    justify-content: space-between;
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .name,
      .num {
        font-size: 12px;
        color: #191b27;
      }
      .date {
        margin-top: 8px;
        font-size: 10px;
        font-family: HarmonyOS_Sans;
        color: #9299a9;
      }
    }
  }
  .tabs {
    width: 100%;
    padding: 0 10px;
    height: 40px;
    font-size: 13px;
    color: #9299a9;
    display: flex;
    justify-content: space-between;
  }
}
