
.container {
  width: 100%;
  height: 100vh;
  position: relative;
  .topImg {
    width: 100%;
  }
  .logo {
    position: absolute;
    top: 115px;
    left: 50%;
    transform: translateX(-50%);
    width: 67px;
    // height: 75px;
  }
  .bottomImg {
    // position: fixed;
    // bottom: 0;
    width: 100%;
    // z-index: 1;
  }
  .content {
    padding: 0 45px;
    z-index: 99;
    margin-top: -25px;
    .item {
      width: 100%;
      border-radius: 3px;
      height: 44px;
      background: #f8f8fb;
      margin-top: 23px;
      display: flex;
      line-height: 44px;
      padding: 0 13px;
      font-size: 14px;
      align-items: center;
      .sendMsg {
        width: 100px;
        color: #00b897;
        text-align: right;
        line-height: 16px;
      }
    }
    .emailC {
      display: flex;
      justify-content: space-between;
    }
    .reginsterBtn {
      width: 100%;
      background: #00b897;
      border-radius: 3px;
      height: 44px;
      margin-top: 30px;
      color: #fff;
      text-align: center;
      line-height: 44px;
    }
  }
}
.jiantou {
  width: 7px;
  height: 4px;
  margin-left: 4px;
}
.tabsBar {
  font-size: 17px;
  display: flex;
  padding-left: 15px;
  line-height: 20px;
  color: #9299a9;
  div {
    margin-right: 20px;
  }
  .activeBtn {
    font-size: 21px;
    color: #00b897;
  }
}

.tongyiBox {
  display: flex;
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap;
  margin-top: 30px;
  .agree {
    padding-left: 15px;
    ::v-deep .van-checkbox__label {
      color: #c2c2c2;
      line-height: 14px;
    }
  }
  a {
    line-height: 16px;
    margin-left: 5px;
  }
  span {
    color: #000000;
    line-height: 14px;
  }
}
.right {
  display: block;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #131520;
}

::v-deep .van-nav-bar {
  background-color: transparent;
  .van-nav-bar__arrow {
    font-size: 24px;
  }
}
::v-deep .van-cell {
  background-color: transparent;
  padding: 0 5px;
  line-height: 44px;
}
::v-deep .van-field__body {
  display: flex;
}
::v-deep .van-popup {
  .van-search--show-action {
    padding-right: 10px;
  }
  .van-search__content {
    border-radius: 5px;
    .van-icon {
      color: #8a8a8a;
    }
  }
  .van-search__action {
    color: #8a8a8a;
  }
  .countriesListBox {
    .listItem {
      font-size: 14px;
      line-height: 54px;
      margin: 0 19px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e5e5e5;
    }
  }
}
