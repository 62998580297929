
.container {
  font-size: 14px;
  height: 100%;
  overflow-y: scroll;

  .top {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: 400;
    color: #5c6573;
    background: #fff;
    > div,
    > a {
      display: flex;
      align-items: center;
    }
    img {
      width: 10px;
      height: 5px;
      margin-left: 2px;
    }
  }
  .list {
    padding: 15px;
    .item {
      width: 100%;
      min-height: 69px;
      background: #f8f8fb;
      border-radius: 3px;
      margin-bottom: 10px;
      padding: 10px;
      .row {
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .name {
          display: flex;
          align-items: center;
          > span:first-child {
            font-size: 16px;
            color: #191b27;
            font-weight: 600;
            margin-right: 10px;
          }
          > span:last-child {
            flex: 1;
            // width: 200px;
            font-size: 12px;
            color: #00b897;
          }
        }
        .num {
          font-size: 18px;
          color: #191b27;
          // letter-spacing: 1px;
          text-align: right;
        }
        .date,
        .balance {
          font-size: 12px;
          color: #9299a9;
        }
      }
      .row:last-child {
        > div {
          flex: 1;
        }
        > div:last-child {
          text-align: right;
        }
      }
    }
  }
}
