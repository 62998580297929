
.list {
  padding: 15px;
  .item {
    width: 100%;
    height: 69px;
    border-radius: 3px;
    border-bottom: 1px solid #ebebf0;
    margin-bottom: 10px;
    padding: 0 10px 5px;
    .row {
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 400;
      .date {
        font-size: 12px;
        color: #9299a9;
      }
    }
    .item_bottom {
      > div {
        flex: 1;
        font-size: 13px;
        color: #131520;
      }
      > div:nth-child(2) {
        text-align: center;
      }
      > div:nth-child(3) {
        text-align: right;
      }
    }
  }
  .return {
    font-size: 13px;
    color: #18ae83;
  }
}
.top_dropdown {
  width: 100%;
  height: 42px;
  background: #fff;
  padding: 0 15px;
  display: flex;
  align-items: center;
  // justify-content: space-around;
  > span {
    position: relative;
    display: inline-block;
    flex: 1;
  }
}
