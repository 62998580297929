
::v-deep .van-cell {
  border: 1px solid #C7CCD8;
  // padding: 5px;

}
.capital_list {
  padding-bottom: 130px;
  .capital_list_item {
    padding: 15px;
    border-bottom: 1px solid #ebebf0;
    .list_item_tit {
      margin: 2px 0 10px;
    }
    .row {
      display: flex;
      font-size: 18px;
      font-weight: 400;
      color: #191b27;
      align-items: center;
    }
    img {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 4px;
    }
    .num_wrap {
      justify-content: space-around;
      > div {
        height: 46px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 400;
        color: #5c6573;
      }
      > div:nth-child(2) {
        align-items: flex-end;
      }
    }
  }
}

.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;

  .Row {
    height: 80px;
    padding: 10px 15px 0;
    background: #f8f8fb;
    display: flex;
    justify-content: space-between;
    .cell {
      width: 255px;
      height: 40px;
      border-radius: 4px;
      padding: 0 20px 0 10px;
      border: 1px solid #c7ccd8;
      font-weight: 400;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        color: #131520;
      }
      .targetSum {
        flex: 1;
        color: #9299a9;
        margin-left: 8px;
        word-break: break-all;
      }
      .sjx {
        color: #5c6573;
      }
    }
    .van-button {
      width: 80px;
      height: 40px;
      // line-height: 40px;
      background: #00b897;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
      text-align: center;
    }
  }

  .desc {
    width: 100%;
    padding: 0 15px;
    font-size: 12px;
    font-weight: 400;
    color: #9299a9;
    margin-bottom: 15px;
  }
}

.content {
  padding: 15px;
  .cell {
    height: 40px;
    font-size: 13px;
    font-weight: 400;
    color: #131520;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

::v-deep {
  .van-checkbox {
    height: 30px;
  }
}
