
.transfer {
  font-size: 16px;
  padding: 0 15px;
}
.title {
  font-size: 18px;
  font-weight: 400;
  color: #191b27;
}
.right {
  font-size: 14px;
  font-weight: 400;
  color: #5c6573;
}

.transfer_box {
  width: 100%;
  height: 86px;
  border-radius: 4px;
  border: 1px solid #c7ccd8;
  margin: 25px 0 10px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .transfer_box_left {
    .left_top,
    .left_bottom {
      width: 255px;
      height: 42px;
      line-height: 42px;
      font-size: 14px;
      font-weight: 400;
      color: #191b27;
      position: relative;
      .span_first {
        color: #9299a9;
        margin-right: 15px;
      }
      img {
        width: 8px;
        height: 8px;
        margin: 5px;
        position: absolute;
        right: 0;
        bottom: 7px;
      }
    }
    .left_top {
      border-bottom: 1px solid #ebebf0;
    }
  }
  .transfer_box_right {
    img {
      width: 55px;
      height: 70px;
    }
  }
}
.transfer_num {
  font-size: 12px;
  font-weight: 400;
  .tit {
    height: 40px;
    color: #191b27;
    line-height: 40px;
  }
  .button {
    width: 58px;
    height: 100%;
    text-align: center;
    border-left: 1px solid #ebebf0;
    color: #00b897;
  }
  .transfer_num_desc {
    height: 40px;
    font-size: 12px;
    line-height: 40px;
    color: #9299a9;
  }
}

::v-deep .van-cell {
  padding-right: 0;
}
::v-deep .transfer_num .van-field {
  width: 100%;
  height: 43px;
  border-radius: 4px;
  border: 1px solid #c7ccd8;
  color: #9299a9;
}
