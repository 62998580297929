
.imgNoData {
  width: 170px;
  height: 105px;
  display: block;
  margin: 0 auto;
  margin-top: 20px;
}
.textNoData {
  text-align: center;
  line-height: 100px;
}
.tradeOrder {
  .item {
    padding: 15px;
    border-bottom: 1px solid #ebebf0;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .right {
        font-size: 12px;
        display: flex;
        align-items: center;
        img {
          width: 6px;
          height: 10px;
        }
        .rightText {
          color: #00b897;
        }
      }
      .left {
        display: flex;
        align-items: center;
      }
      .duiming {
        font-size: 16px;
        font-weight: 600;
        color: #191b27;
        margin-left: 15px;
      }
      .type {
        // width: 36px;
        height: 20px;
        background: rgba(255, 96, 96, 0.1);
        border-radius: 2px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: #ED0071;
        line-height: 20px;
        padding: 0 4px;
      }
      .type2 {
        // width: 36px;
        height: 20px;
        background: rgba(255, 96, 96, 0.1);
        border-radius: 2px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: #ED0071;
        line-height: 20px;
        background: #d6ede5 !important;
        color: #0AA869;
        padding: 0 2px;
      }
    }
    .bottom {
      display: flex;
      margin-top: 15px;
      justify-content: space-between;
      align-items: center;
      .itembottom {
        line-height: 19px;
        font-size: 13px;
        margin-top: 0;


        .tit {
          font-weight: 400;
          color: #9299a9;
        }
        .content {
          color: #191b27;
          margin-top: 5px;
          white-space: nowrap;
          font-size: 10px;
        }
      }
      .item1 {
        text-align: left;
      }
      .item2 {
        text-align: center;
      }
      .item3 {
        text-align: right;
      }
    }
  }

  .itemhui {
    color: #a5adbf;
    .top {
      .right {
        color: #a5adbf;
      }

      .duiming {
        color: #a5adbf;
      }
    }
    .bottom {
      .itembottom {
        .tit {
          font-weight: 400;
          color: #a5adbf;
        }
        .content {
          color: #a5adbf;
          margin-top: 5px;
          white-space: nowrap;
          font-size: 10px;
        }
      }
    }
  }
  .popupHome {
    padding: 0 17px;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    .typeTit {
      font-size: 16px;
      font-weight: 600;
      color: #191b27;
      line-height: 53px;
      margin-top: 12px;
    }
    .coinBox {
      display: flex;
      align-items: center;
      .inputBox {
        max-width: 100px;
        border: 1px solid #9299a9;
        height: 34px;
        border-radius: 4px;
      }
      .coinTypeBox {
        width: 90px;
        border: 1px solid #9299a9;
        height: 34px;
        border-radius: 4px;
        line-height: 32px;
        font-size: 14px;
        padding-left: 15px;
        position: relative;
        .jiantou {
          position: absolute;
          right: 10px;
          top: 15px;
          width: 10px;
          height: 5px;
        }
      }
      .xie {
        margin: 0 15px;
      }
    }
    .boxHome {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .itemBox {
        height: 34px;
        background: #f6f6f6;
        border-radius: 4px;
        color: #9299a9;
        line-height: 32px;
        padding: 0 8px;
        margin-right: 10px;
        margin-bottom: 10px;
        font-size: 14px;
        box-sizing: border-box;
        border: 1px solid #f6f6f6;
      }
      .selectBox {
        background: rgba(0, 184, 151, 0.15);
        border: 1px solid #00b897;
        color: #191b27;
        box-sizing: border-box;
      }
    }
    .bottomBox {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      height: 46px;
      text-align: center;
      line-height: 46px;
      color: #fff;
      font-size: 18px;
      left: 0;
    }
    .itemBtn {
      width: 50%;
    }
    .itemBtn1 {
      background: #272a3b;
    }
    .itemBtn2 {
      background: #00b897;
    }
  }
}
::v-deep .van-nav-bar .van-icon {
  color: #000000;
}
::v-deep .van-cell {
  padding: 0px 8px;
}
::v-deep .van-field__control {
  line-height: 32px;
}
.boxHomeList {
  width: 82px;
  // max-height: 190px;
  overflow-y: scroll;
  text-align: center;
  .selectItem {
    text-align: center;
    width: 82px;
    height: 40px;
    line-height: 40px;
    .van-cell__value--alone {
      text-align: center;
    }
  }
}
