
::v-deep {
  .van-switch {
    font-size: 14px;
  }
  .van-search {
    width: 90px;
    height: 30px;
    background: #ffffff;
    border-radius: 15px;
    overflow: hidden;
    .van-search__content {
      height: 25px;
      background: #ffffff;
      padding-left: 0;
    }
    .van-field__body,
    .van-field__value {
      height: 100%;
      line-height: 30px;
    }
    .van-field__value {
      .van-field__body {
        display: flex;
        align-items: center;
        input {
          font-size: 12px;
          color: #5c6573;
        }
        i {
          font-size: 16px;
        }
      }
    }
    .van-icon-clear {
      transform: translate(1px, 1px);
    }
  }
}
