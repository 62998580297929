
.securityCenter {
  min-height: 100vh;
  width: 100vw;
  background-color: #f8f8f8;

  .navbar {
    position: fixed;
    width: 100vw;
    background-color: #ffffff;
    // /deep/ .van-ellipsis{
    //   color: #ffffff !important;
    // }

    // /deep/ .van-icon {
    //   color: #ffffff !important;
    // }
  }

  .commonhead {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 44px;
    line-height: 44px;
    font-size: 2.8vw;
    background: #ffffff;
    color: #191b27;
    text-align: center;
    border-bottom: solid 1px #ebebf0;
  }

  .commonhead-icon {
    width: 10px;
    margin-top: 14px;
    margin-left: 20px;
    float: left;
  }

  .commonhead-tit {
    font-size: 4.2vw;
    margin-left: -10vw;
  }

  .commonhead-user {
    position: absolute;
    width: 6.8vw;
    top: 1.8vw;
    right: 28px;
  }

  .navbar-handle {
    width: 18px;
  }

  .van-hairline--bottom::after {
    border-bottom-width: 0rem;
  }

  .main-content {
    width: 100vw;
    height: 100vh;
    // padding: 30px;
    display: flex;
    display: -webkit-flex;
    /* Safari */
    flex-direction: column;
  }

  .top-functional {
    width: 92vw;
    // height: 178px;
    margin-left: 4vw;
    margin-top: 10px;
    border-radius: 4px;
    background-color: #ffffff;
  }

  .sec-functional {
    width: 92vw;
    // height: 108px;
    margin-left: 4vw;
    border-radius: 4px;
    background-color: #ffffff;
  }

  .item-cell {
    height: 52.5px;
    background-color: transparent;
    padding: 14.5px;
    display: flex;
    align-items: center;

    .receipt-icon {
      width: 14px;
      height: 14px;
      margin-top: 5px;
    }

    .receipt-title {
      color: #d5d5d5;
      font-size: 15px;
      margin-left: 13px;
    }

    .receipt-content {
      color: #d5d5d5;
      font-size: 15px;
      margin-left: 13px;
    }

    .van-cell__title {
      display: flex;
      align-items: center;

      .item-title {
        color: #191b27;
        flex: 1;
      }

      .item-custom-bx {
        position: relative;
        // display: inline-block;
        // float: right;
        color: #9299a9;
        line-height: 1.2;
        text-align: right;
        flex: 1;
        display: flex;
        justify-content: flex-end;
      }
    }

    .ic-notice {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }

    .van-cell__value {
      color: #9299a9;
    }
  }

  .item-cell.top-cell {
    height: 70px;
  }

  .tip-txt {
    margin: 12px 15px;
    font-size: 12px;
    color: #5c6573;
  }

  .item-cell::after {
    border-bottom: 1.5px solid #ebebf0;
  }

  .cell-level {
    width: 100%;

    .level-bx {
      width: 100%;
    }

    .level-tit {
      display: inline-block;
      font-size: 15px;
    }

    .level-tip {
      display: inline-block;
      font-size: 12px;
      color: #00b897;
      float: right;
    }

    .levelTip1 {
      color: #00b897;
    }

    .levelTip2 {
      color: #e99c22;
    }

    .levelTip3 {
      color: #0AA869;
    }

    .level-bar {
      display: inline-block;
      width: 98.33px;
      height: 4px;
      background-color: #d5d5d5;
    }

    .selected {
      background-color: #00b897;
    }

    .selected1 {
      background-color: #00b897;
    }

    .selected2 {
      background-color: #e99c22;
    }

    .selected3 {
      background-color: #0AA869;
    }

    .mg-lft {
      margin-left: 10px;
    }
  }
}

// /deep/ {
//   /* 滚动条隐藏 */
//   ::-webkit-scrollbar {
//     width: 0px;
//   }
// }