
.row {
  display: flex;
}
.flexC {
  justify-content: center;
  align-items: center;
}
.assetRecords {
  height: 100%;
  overflow-y: scroll;
}
.button_wrap {
  justify-content: space-around;
  align-items: center;
  .button {
    width: 100px;
    line-height: 24px;
    text-align: center;
    border-radius: 2px;
    border: 1px solid #fff;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
  }
}
.capital_top {
  position: fixed;
  top: 50px;
  z-index: 999;
  width: 100%;
  padding: 20px 15px;
  // background: url('~@/assets/img/assets/bg_capital.png') no-repeat center;
  // background-size: cover;
  background: linear-gradient(90deg, #00b897 0%, #00b897 100%);
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  .capital_top_title {
    width: 100%;
    text-align: center;
    height: 30px;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    > span {
      margin-right: 20px;
    }
    .icon {
      width: 18px;
    }
    .icon_eyeoff {
      width: 18px;
      height: 8px;
    }
  }

  .total {
    height: 40px;
    font-weight: bold;
    justify-content: center;
    align-items: flex-end;
    //margin-bottom: 20px;
    > span:first-child {
      font-size: 30px;
      line-height: 1;
      font-family: DINCondensed-Bold, DINCondensed;
    }
    > span.info {
      font-size: 13px;
      font-weight: 600;
      margin-left: 5px;
    }
  }
}

.capital_list {
  // margin-top: 168px;
  margin-top: 213px;
  padding-bottom: 50px;
  .capital_list_item {
    padding: 15px;
    border-bottom: 1px solid #ebebf0;
    .list_item_tit {
      margin: 2px 0 10px;
      justify-content: space-between;
      .col {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-size: 12px;
        font-weight: 400;
        color: #5c6573;
      }
    }
    .row {
      font-size: 16px;
      font-weight: 400;
      color: #191b27;
      align-items: center;
    }
    img {
      width: 18px;
      height: 18px;
      border: 1px solid;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 4px;
    }
    .num_wrap {
      justify-content: space-around;
      > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 400;
        color: #5c6573;
        height: 40px;
      }
      > div:nth-child(2) {
        align-items: center;
      }
      > div:nth-child(3) {
        align-items: flex-end;
      }
    }
    .button_wrap {
      .button {
        background: #00b897;
      }
      .button_disable {
        background: #ccc;
      }
    }
  }
  .capital_list_item_active {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    background: #f8f8fb;
    .button_wrap_active {
      border-top: 1px solid #ebebf0;
      margin-top: 15px;
      .matchmaking {
        height: 30px;
        font-size: 10px;
        color: #999;
        justify-content: space-between;
        margin-bottom: 15px;
      }
      .button_wrap {
        .button {
          color: #fff;
          border: none;
          opacity: 1;
        }
      }
    }
  }
}
::v-deep .van-sticky--fixed {
  top: 50px;
}

.flash_cash {
  position: absolute;
  right: 0;
  top: 0;
  width: 80px;
  height: 37px;
  line-height: 37px;
  // background: rgba(160, 94, 13, 0.5);
  background: linear-gradient(90deg, #87c5fc 0%, #00b897 100%);
  border-radius: 0px 5px 0px 5px;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
}
