
.popupCertain2{
  padding: 10px;
  .van-tabs__wrap{
      height: auto;
  }
  .subTitle{
      padding-bottom: 10px;
      color: #a2a2a2;
      border-bottom: 1px solid #eee;
  }
  .tab1{
      .van-tabs__nav--card{
          border: none;
          margin: 0;
          border-radius: 0;
          .van-tab{
              border: none;
          }
      }
  }
  .tab2{
      .van-tabs__nav--card{
          margin: 0;
          border-radius: 0;
      }
  }
  .row{
      padding-top: 10px;
      .Nactive{
          padding: 5px 10px;
          background: #f8f8f8;
          color: #a2a2a2;
          margin-left: 10px;
      }
      .active{
          padding: 5px 10px;
          background: #e7f7f2;
          color: #00b897;
          margin-left: 10px;
      }
      .right{
          text-align: right;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        span {
          white-space: nowrap;
        }
      }
  }
  .buttoncon{
    margin: 15px 0;
  }
  .describe{
    margin-top: 10px;
    padding: 5px;
    background-color: #f8f8f8;
    div{
      padding: 5px;
    }
  }
  .title{
    border-bottom: 1px solid #e3e5eb;
    margin-bottom: 10px;
    .titletop{
      color: #000;
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 5px;
    }
    .titlebottom{
      // font-size: 16px;
      color: #000;
      padding-bottom: 5px;
    }
  }
  .sub{
    padding-top: 5px;
  }
  .pper{
      margin: 10px 0;
      .pperinput{
          background: #f8f8f8;
          .inputRight{
              color: #a2a2a2;border-left: 1px solid #ddd;padding: 0 10px;margin-left: 10px;
          }
          .inputLeft{
              color: #a2a2a2;
          }
      }
  }
  .inputBottom{
          padding: 3px 0;
          color: #00b897;
  }
  .activeNum{
    border: 1px solid #00b897;
    display: flex;
    div{
      flex: 1;
      height: 30px;
      border-right: 1px solid #00b897;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    div:last-child{
      border-right: none;
    }
    .active{
      color: #fff;
      background-color: #00b897;
    }
  }
}

