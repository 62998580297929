
.assets {
  font-size: 16px;

  .assets_top {
    position: relative;
    height: 230px;
    // background: url('~@/assets/img/assets/bg.png') no-repeat center;
    // background-size: cover;
    background: linear-gradient(90deg, #00b897 0%, #00b897 100%);
    .asset_records {
      width: 100%;
      text-align: right;
      height: 30px;
      line-height: 30px;
      padding-right: 15px;
    }
    .assets_total {
      width: 100%;
      font-weight: 400;
      color: #fff;
      line-height: 23px;
      padding: 60px 0 0 15px;
      word-wrap: break-word;
      .icon {
        width: 20px;
        height: 13px;
        margin-right: 40px;
        margin-left: 10px;
      }
      .icon_eyeoff {
        width: 18px;
        height: 8px;
      }
      .total {
        font-size: 36px;
        font-weight: bold;
        color: #fff;
        line-height: 48px;

        font-family: DINCondensed-Bold, DINCondensed;
      }
    }
  }

  .assets_distributed {
    position: absolute;
    top: 145px;
    left: 50%;
    transform: translateX(-50%);
    width: 345px;
    height: 196px;
    background: #ffffff;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    .assets_distributed_title {
      height: 45px;
      line-height: 45px;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
    }
    .chart {
      height: 146px;
      width: 345px;
    }
    .legend {
      position: absolute;
      right: 0;
      bottom: 8%;
      width: 60%;
      min-height: 60%;
      padding: 0 10px 0 5px;
      .legend_item {
        height: 20px;
        margin-bottom: 5px;
        color: #5c6573;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        i {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #ccc;
          margin-right: 6px;
        }
      }
    }
  }

  .assets_list {
    margin-top: 140px;
    .assets_list_title {
      flex: 1;
      font-size: 15.5px;
      font-weight: 400;
      color: #191b27;

      > div {
        font-size: 12px;
        color: #9299a9;
        padding: 2px 0;
      }
    }

    .van-cell__value,
    .van-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      height: auto;
    }
    .van-icon {
      font-size: 0.36rem;
      color: #9299a9;
    }
    .van-cell__value {
      flex: 2;
      font-size: 0.34rem;
      color: #191b27;
      margin-right: 5px;
      justify-content: flex-end;
    }
  }
  .row {
    display: flex;
  }
  .flexC {
    align-items: center;
  }
  .icon {
    width: 20px;
    height: 20px;
  }
}
::v-deep .van-cell__title {
  flex: 2;
}
