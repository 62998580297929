
.setPassword {
  font-size: 12px;
  background-color: #f8f8fb;

  .listBox {
    padding: 13px 15px;
  }
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}
::v-deep .van-tab__text--ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
