
.setPassword {
  font-size: 12px;
  background: #f8f8f8;
}

.contentHome {
  padding: 10px;
  height: 100vh;

  .inputBox {
    background-color: #fff;
    border-radius: 5px;
  }

  .fistTips {
    margin-top: 10px;
    display: flex;
    line-height: 20px;
    align-items: center;

    img {
      width: 12px;
      height: 12px;
      margin-right: 5px;
    }
  }
}

.yesBtn {
  width: 88%;
  height: 46px;
  background: #00b897;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  line-height: 46px;
  text-align: center;
  margin: 0 auto;
  margin-top: 50px;
}

::v-deep {
  .van-nav-bar .van-icon {
    color: #000;
  }

  .van-field__control {
    text-align: right;
  }
}
