
.van-popup {
  max-height: 70%;
  min-height: 30%;
  padding: 0 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .title {
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #000000;
  }
  .van-field {
    width: 100%;
    height: 43px;
    border-radius: 4px;
    border: 1px solid #c7ccd8;
    color: #9299a9;
    margin-bottom: 15px;
    .van-field__control {
      color: #9299a9;
    }
  }
  .btn_wrap {
    margin: 0;
  }
}
.van-button {
  padding: 0;
  border: none;
  color: #00b897;
  background-color: transparent;
}
.van-button--disabled {
  color: #00b897;
}
