
.userCenter {
  padding: 0;
}

.top-back {
  width: 100%;
  height: 80px;
  padding-top: 24px;

  .btn-back {
    width: 34px;
    height: 34px;
    text-align: center;
  }

  .ic-back {
    height: 17px;
    width: 10px;
  }
}

.user-top {
  position: relative;
  width: 100%;
  height: 240px;

  // background-image: url('../../assets/img/userCenter/topbg.png');
  // background-repeat: no-repeat;
  // background-size: 100%;
  .bgcolor {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 150px;
    background: linear-gradient(90deg, #00b897 0%, #00b897 100%);
  }
}

.user-bck {
  width: 94%;
  margin-left: 3%;
}

.user-head {
  position: absolute;
  width: 64px;
  height: 64px;
  z-index: 10;
  left: 30px;
  top: 118px;

  img {
    border-radius: 50%;
  }
}

.user-rframe {
  position: absolute;
  padding: 0;
  height: 80px;
  left: 110px;
  top: 120px;
  color: #00b897;
}

.user-name {
  display: inline-block;
  width: 100%;
  font-weight: bold;
  font-size: 18px;
  float: left;
}

.user-numb {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  float: left;
}

.user-uid {
  display: flex;
  width: 100%;
  font-size: 12px;
  float: left;
}

.ic-copy {
  display: inline-block;
  position: absolute;
  width: 15px;
  margin-left: 10px;
}

.tag-v {
  margin-left: 6px;
  width: 18px;
}

.tag-score {
  width: 44px;
  margin-top: -4px;
}

.v-val {
  color: #d2993e;
  font-size: 14px;
}

.study-time {
  position: absolute;
  width: 100%;
  height: 200px;
  top: 210px;
}

.vline {
  position: absolute;
  width: 2px;
  height: 100px;
  background: rgb(162, 209, 253);
  top: 50px;
  left: 50%;
  z-index: 10;
}

.time-item {
  width: 50%;
  height: 100%;
  float: left;
  text-align: center;
}

.time-tit {
  display: inline-block;
  width: 100%;
  margin-top: 50px;
  color: #becce9;
  font-size: 3.8vw;
}

.time-val {
  font-size: 5.8vw;
  font-weight: 600;
  color: #fff;
}

.time-unit {
  font-weight: 400;
  font-size: 4vw;
}

.usercenter-cell .van-cell__left-icon {
  font-size: 60px;
}

.usercenter-cell .van-cell__title {
  padding-left: 20px;
}

.contact-us {
  position: relative;
  width: 100%;
  height: 220px;
  float: left;
  background: #fff;
  padding-left: 120px;
  padding-top: 10px;
  font-size: 30px;
  color: #6e6e6e;
}

.contact-p {
  display: inline-block;
  width: 100%;
  padding: 10px 0;
}

.div-exit {
  position: relative;
  width: 100%;
  height: 150px;
  margin-top: 15px;
  background: #fff;
  float: left;
}

.exit-btn {
  position: absolute;
  width: 60%;
  height: 80px;
  line-height: 80px;
  border: 1px solid #62697c;
  text-align: center;
  border-radius: 50px;
  color: #62697c;
  top: 30px;
  left: 50%;
  margin-left: -30%;
}

.ic-cellicon {
  margin-top: 5px;
  width: 16px;
  height: 16px;
}

.cell-title {
  color: #191b27;
  font-size: 14px;
  font-weight: 600;
}

.errTips {
  font-size: 12px;
  color: red;
  padding-left: 15px;
  margin-bottom: 4px;
}
