.index {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  overflow-x: hidden;
}

.tab-bar {
  display: flex;
  justify-content: space-around;
  height: 60px;
  /* box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.33); */
  background-color: rgb(248, 247, 247);
  padding-bottom: 14px;
}

.tab-bar-item {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
  height: 100%;
}

.tab-bar-item img {
  width: 25px;
  height: 25px;
}

.tab-bar-item span {
  font-size: 13px;
  line-height: 12px;
  text-align: center;
}

.active {
  font-size: 13px;
  color: #00b897;
}

.link {
  flex: 1;
  display: block;
  position: relative;
  color: #9299a9;
}

.unread-msg-num {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 2px;
  left: 65px;
  z-index: 1000;

  min-width: 17.8px;
  min-height: 17.8px;
  border-radius: 8px;

  font-size: 12px;
  text-align: center;
  color: #fff;
  background-color: #00b897;
}
