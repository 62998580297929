
.home {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  color: #888888;

  .log {
    flex-direction: row-reverse;
    display: flex;
    margin-top: 14px;

    .right {
      color: #888888;

    }
  }

  .changBox {
    border: 1px solid #D1D4DD;
    border-radius: 6px 6px 6px 6px;
    margin-top: 14px;

    .changItemBox {
      height: 100px;
      padding: 15px;

      .topBox {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .itemTitle {
          font-size: 15px;
          color: #000000;
          line-height: 21px;
        }

        .balance {
          font-size: 12px;
          color: #888888;
          line-height: 21px;
        }
      }

      .bottomBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        color: #000;
        font-size: 14px;

        .available {
          flex: 1;
        }

        .maxNumber {
          padding-right: 14px;
          border-right: 1px solid #EBEBF0;

        }

        .selectFromMoney {
          display: flex;
          align-items: center;
          padding-left: 15px;

          .selectFormBtn {
            display: flex;
            align-items: center;

            .jiantou {
              width: 10px;
              margin-left: 4px;
            }
          }
        }
      }

      .loginBtn {
        display: block;
        width: 315px;
        height: 40px;
        background: #00b897;
        border-radius: 6px 6px 6px 6px;
        text-align: center;
        line-height: 40px;
        font-size: 15px;
        font-weight: 600;
        color: #fff;
        margin-top: 14px;
      }

    }

    .bottomBorder {
      border-bottom: 1px solid #D1D4DD;
    }

  }

  .desc {
    margin-top: 20px;
  }
}

.contentDialog {
  padding: 15px;

  .cell {
    height: 40px;
    font-size: 13px;
    font-weight: 400;
    color: #131520;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}


.van-cell {
  padding: 0;
}
