
.ioan {
  width: 100%;
  position: relative;

  .back {
    position: fixed;
    top: 10px;
    left: 15px;
    width: 14px;
    z-index: 99;
  }

  .topBox {
    position: relative;
    background-image: url("../../assets/img/userCenter/icon.png");
    height: 170px;
    width: 100%;
    background-size: 100% 100%;

    .xuan {
      position: absolute;
      top: 63px;
      left: 24px;
      color: #fff;
      font-weight: 600;
      line-height: 23px;
      font-size: 20px;
    }
  }

  .contant {
    margin-top: -18px;
    border-radius: 18px;
    z-index: 99;
    position: relative;
    background-color: #fff;
    width: 100%;
    padding: 20px 15px;
    font-size: 16px;

    .text {
      margin-top: 10px;
      font-size: 12px;
      color: #888888;
      margin-bottom: 12px;
    }
  }

  .btn {
    position: fixed;
    bottom: 40px;
    height: 44px;
    background: linear-gradient(90deg, #00b897 0%, #00b897 100%);
    border-radius: 22px 22px 22px 22px;
    color: #FFFFFF;
    font-size: 15px;
    line-height: 44px;
    width: 80%;
    left: 10%;
    text-align: center;
    z-index: 99;
  }
}
