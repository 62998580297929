
.ranking {
  .cell {
    width: 100%;
    display: flex;
    align-items: center;
    > div {
      height: 40px;
      flex: 1;
      font-size: 12px;
      color: #9299a9;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > div:first-child {
      justify-content: flex-start;
    }
    > div:last-child {
      justify-content: flex-end;
    }
  }
  .list {
    .item {
      height: 60px;
      font-size: 11px;
      color: #9299a9;
      border-bottom: 1px solid #ebebf0;
      display: flex;
      align-items: center;
      .sum {
        flex: 1;
        > div:first-child {
          margin-bottom: 5px;
          > span:first-child {
            font-size: 16px;
            font-weight: 600;
            color: #191b27;
          }
        }
      }
      .price {
        flex: 0.8;
        > div:first-child {
          font-size: 16px;
          font-weight: 600;
          color: #191b27;
          margin-bottom: 5px;
        }
      }
      .button {
        padding: 0 10px;
        min-width: 73px;
        height: 33px;
        line-height: 33px;
        text-align: center;
        border-radius: 5px;
        background: rgba(24, 174, 131, 1);
        font-size: 16px;
        font-weight: 600;
        color: #ffffff;
      }
      .button2 {
        background: #a8a8ac;
      }
      .button3 {
        background: #EC0070;
      }
    }
  }
  .typeBox {
    display: flex;
    flex-direction: column;
    img {
      width: 5px;
    }
    img:first-child {
      margin-bottom: 2px;
    }
  }
  .typeBox2 {
    img {
      transform: rotateX(180deg);
    }
  }
}
::v-deep {
  .van-tabs {
    .van-tabs__content {
      padding: 0 15px;
    }
  }
}
::v-deep .van-tab__text--ellipsis {
  //white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;
  -webkit-box-orient: horizontal;
}
