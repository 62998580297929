
::v-deep .trade {
  .van-cell::after {
    border-bottom: 0px;
  }
}
::v-deep .van-popover__wrapper{
  display: flex!important;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

::v-deep .van-cell {
  padding: 5px 10px;
}

::v-deep .buyMoney {
  .van-cell {
    padding: 8px 10px;
  }
}

::v-deep .van-divider {
  margin: 0;
}

.van-cell::after {
  border-bottom: 0px;
}
