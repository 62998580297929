
.ieo {
  font-size: 12px;
  background-color: #f8f8f8;
  position: relative;
  width: 100%;
  height: 100%;
  // display: flex;
  /* 设置子元素的排列方向 */
  // flex-direction: column;
  /* 设置子元素在该方向上的对齐方式 */
  // justify-content: space-between;
  min-height: 100vh;
  .banner {
    width: 100%;
    height: 141px;
  }
  .tabBar {
    font-size: 16px;
    .titleSmallNum {
      font-size: 10px;
      line-height: 10px;
      //   vertical-align: text-bottom;
      position: absolute;
      top: 0px;
      right: -20px;
      width: 18px;
      text-align: left;
    }
  }
  .listBox {
    padding: 12px 15px;
    padding-bottom: 70px;
    // padding-top: 0;
    // overflow-y: scroll;
    // position: absolute;
    // top: 235px;
    // bottom: 0;
    // left: 0;
    // right: 0;
    .listItem {
      background-color: #fff;
      border-radius: 5px;
      padding: 10px 10px;
      line-height: 24px;
      margin-bottom: 15px;
      .line1 {
        display: flex;
        .itemTotal {
          flex: 1.2;
        }
        .itemOver {
          flex: 1;
        }
      }

      .timeBox {
        line-height: 36px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 50px;
}
::v-deep .van-tab {
  flex: auto;
  font-size: 14px;
}
::v-deep .van-tab__text--ellipsis {
  position: relative;
  overflow: visible;
  white-space: nowrap;
}
