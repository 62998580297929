
::v-deep .van-nav-bar__arrow {
  font-size: 24px;
}
.title_box {
  margin: 30px 0;
  padding: 0 35px;

  .title {
    font-size: 16px;
    font-weight: 600;
  }
  .tips {
    font-size: 12px;
    color: #e95252;
    margin-top: 10px;
    display: flex;
    // align-items: center;
    padding-left: 20px;
    position: relative;
    &::before {
      position: absolute;
      left: 0;
      top: 0;
      content: '!';
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 1.5px solid;
      font-weight: 600;
      margin-right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.section {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .userName_cell {
    width: 300px;
    margin: 56px 0;
    .userName {
      background: rgba(204, 204, 204, 0.2);
      border-radius: 4px;
    }
  }
  .button {
    width: 300px;
    height: 35px;
    background: #00b897;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: Adobe Heiti Std;
    color: #fff;
  }
}
