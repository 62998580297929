
.popupCertain {
  padding: 10px;

  .van-tabs__wrap {
    height: auto;
  }

  .van-tabs__nav--card {
    margin: 0;
  }

  .buttoncon {
    margin: 15px 0;
  }

  .describe {
    margin-top: 10px;
    padding: 5px;
    background-color: #f8f8f8;

    div {
      padding: 5px;
    }
  }

  .title {
    border-bottom: 1px solid #e3e5eb;

    .titletop {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 5px;
    }

    .titlebottom {
      // font-size: 16px;
      color: #000;
      padding-bottom: 5px;
    }
  }

  .sub {
    text-align: center;
    padding: 10px;
    color: #5C6573;
  }

  .pper {
    text-align: center;
    padding: 5px;
    margin-bottom: 10px;

    .popupStepper {

      .van-stepper__minus,
      .van-stepper__plus {
        background-color: white;
        border: 1px solid #00b897;
        color: #00b897;
        border-radius: 5px;
      }

      .van-stepper__input {
        color: #00b897!important;
        -webkit-text-fill-color: #00b897;
        font-size: 18px;
        background-color: #fff;
      }

      .van-stepper__minus--disabled {
        background-color: white;
        border: 1px solid #e3e5eb;
        color: #e3e5eb;
        border-radius: 5px;
      }
    }
  }

  .activeNum::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .activeNum {
    overflow-x: auto;
    border: 1px solid #00b897;
    white-space: nowrap;

    div {
      display: inline-block;
      height: 30px;
      border-right: 1px solid #00b897;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      line-height: 30px;
      text-align: center;
      width: 70px;
    }

    div:last-child {
      border-right: none;
    }

    .active {
      color: #fff;
      background-color: #00b897;
    }
  }
}