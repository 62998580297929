
::v-deep {
  .van-swipe-cell__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .delete-button {
    height: 100%;
    background: #00b897;
    border: none;
  }
}
