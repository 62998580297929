
.setPassword {
  font-size: 12px;
}

.content {
  background-color: #f8f8f8;
  padding: 10px 10px;
  height: 100vh;
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}

::v-deep .van-field {
  padding: 0;
}

.xian {
  width: 100%;
  height: 15px;
  background-color: #f8f8f8;
}

.next {
  width: 100%;
  height: 46px;
  background: #00b897;
  border-radius: 4px;
  text-align: center;
  line-height: 46px;
  font-size: 18px;
  color: #fff;
  margin-top: 50px;
}

::v-deep .van-popup {
  .van-search--show-action {
    padding-right: 10px;
  }

  .van-search__content {
    border-radius: 5px;

    .van-icon {
      color: #8a8a8a;
    }
  }

  .van-search__action {
    color: #8a8a8a;
  }

  .countriesListBox {
    .listItem {
      font-size: 14px;
      line-height: 54px;
      margin: 0 19px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e5e5e5;
    }
  }
}
