#app {
    .van-nav-bar__arrow {
        font-size: 20px;
    }

    .van-popover {
        // left: 10px !important;
        width: 120px;
        height: 100%;

        .van-popover__arrow {
            left: 20%;
        }

        .van-popover__content {
            .van-popover__action-text {
                justify-content: flex-start;
            }

            .van-popover__action {
                width: 100%;
            }

            .popover_item_active {
                color: #00b897;
            }

            .popover_item_value {
                height: 42px;
                line-height: 42px;
                background: #f8f8fb;
            }
        }
    }

    .van-action-sheet {
        max-height: 50%;
    }

    .van-sticky {
        background: #fff;
    }

    .van-cell__title {
        line-height: 1;
        display: flex;
        align-items: center;
    }

    .assets .van-cell {
        line-height: 1.6;
    }

}

.van-popover__action {
    width: auto;
}

.van-popover {
    overflow-y: scroll;
    max-height: 50%;
}

.van-nav-bar__right > a{
    white-space: pre-wrap;
    overflow-wrap: break-word;
    max-width: 70px;
    line-height: 16px;
    text-align: right;
    word-break: break-all;
}