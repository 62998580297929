
.news {
  background: #f8f7fc;
  .content {
    padding: 15px;
    padding-bottom: 50px;
    .listItem {
      width: 345px;
      height: 66px;
      background: #ffffff;
      border-radius: 4px;
      margin-bottom: 10px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 15px;
      .box {
        width: 5px;
        height: 29px;
        background: #00b897;
        border-radius: 3px;
      }
      .showcontent {
        flex: 1;
        padding-left: 10px;
        font-size: 15px;
        font-weight: 400;
        color: #191b27;
        line-height: 22px;
        .date {
          font-size: 12px;
        }
      }
      .right {
        width: 15px;
        height: 15px;
      }
    }
  }
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}
