
::v-deep {
  .van-swipe__indicators {
    .van-swipe__indicator {
      width: 8px;
      height: 2px;
      border-radius: 2px;
    }
  }

  .van-grid {
    // margin: 10px 0;
    background-color: #fff;
    margin: 0 15px;
    margin-bottom: 10px;
    border-radius: 4px;

    .van-grid-item {
      img {
        width: 32px;
        height: 32px;
        margin-bottom: 7px;
      }
    }

    .text {
      font-size: 12px;
      color: #191b27;
    }

    .van-grid-item__content {
      padding-left: 0;
      padding-right: 0;
      background: transparent;
    }
  }

  .van-dialog__footer {
    .van-dialog__confirm {
      background-color: #dcb067;
    }

    .van-dialog__cancel {
      background-color: #f3f3f3;
      color: #191b27;
    }
  }
}

.assets_total {
  width: 90%;
  font-weight: 400;
  color: #000;
  padding: 25px 0 0 15px;
  word-wrap: break-word;
  box-sizing: border-box;
  background-color: #00B897;
  margin: 0 auto;
  border-radius: 8px 8px 8px 8px;
  color: rgba(255, 255, 255, 0.64);
  margin-top: 10px;

  .flexC {
    display: flex;
    align-items: center;
  }

  .icon {
    width: 20px;
    // height: 13px;
    margin: 0 20px;
    transform: translateY(1px);
  }

  .icon_eyeoff {
    width: 18px;
    // height: 8px;
  }

  .total {
    font-weight: bold;
    line-height: 50px;

    >span:first-child {
      font-size: 30px;
      font-family: PingFang SC-Semibold, PingFang SC;
      color: #fff;
    }
  }
}
