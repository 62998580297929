
.popupCertain1{
  padding: 10px;
  .van-tabs__wrap{
      height: auto;
  }
  .tab1{
      .van-tabs__nav--card{
          border: none;
          margin: 0;
          .van-tab{
              border: none;
          }
      }
  }
  .tab2{
      .van-tabs__nav--card{
          margin: 0;
      }
  }
  .buttoncon{
    margin: 15px 0;
  }
  .describe{
    margin-top: 10px;
    padding: 5px;
    background-color: #f8f8f8;
    div{
      padding: 5px;
    }
  }
  .title{
    border-bottom: 1px solid #e3e5eb;
    margin-bottom: 10px;
    .titletop{
      color: #000;
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 5px;
    }
    .titlebottom{
      // font-size: 16px;
      color: #000;
      padding-bottom: 5px;
    }
  }
  .sub{
    padding-top: 5px;
  }
  .pper{
      margin: 10px 0;
      .pperinput{
          background: #f8f8f8;
      }
  }
  .activeNum{
    border: 1px solid #F10E38;
    display: flex;
    div{
      flex: 1;
      height: 30px;
      border-right: 1px solid #F10E38;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    div:last-child{
      border-right: none;
    }
    .active{
      color: #fff;
      background-color: #F10E38;
    }
  }
}
