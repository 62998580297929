
.setPassword {
  font-size: 12px;
  background-color: #f8f8f8;
  .content {
    padding-top: 12px;
    border-radius: 8px;
    .van-cell-group {
      border-radius: 10px;
    }
  }
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}
