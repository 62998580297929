
.container {
  padding: 0 15px;
  //   background-color: #131520;
  //   color: #fff;
  //   section {
  //     width: 100%;
  //     height: 100%;
  //     background: url('~@/assets/img/lock/bg.png') no-repeat;
  //     background-size: 110%;
  //     .wrap {
  //       width: 100%;
  //       padding: 0 15px 20px;

  //       .box {
  //         height: 140px;
  //         padding: 25px 0;
  //         display: flex;
  //         flex-direction: column;
  //         align-items: center;
  //         justify-content: space-between;
  //         .slogan {
  //           width: 230px;
  //           height: 50px;
  //         }
  //         p {
  //           width: 282px;
  //           font-size: 13px;
  //           text-align: center;
  //           line-height: 1.5;
  //         }
  //       }
  //       .card-box1 {
  //         width: 100%;
  //         min-height: 240px;
  //         background: url('~@/assets/img/lock/card_bg1.png') no-repeat;
  //         background-size: cover;
  //         position: relative;
  //         padding: 20px 15px 0;
  //         .row {
  //           display: flex;
  //           align-items: center;
  //           justify-content: space-around;
  //           font-size: 14px;
  //           margin-top: 30px;
  //           > div {
  //             // width: 100px;
  //           }
  //           .col {
  //             display: flex;
  //             flex-direction: column;
  //             align-items: center;
  //             justify-content: center;
  //             > span {
  //               text-align: center;
  //             }
  //           }
  //           img {
  //             width: 50px;
  //             height: 50px;
  //             margin-bottom: 10px;
  //           }
  //         }
  //       }
  //       .card-box2 {
  //         width: 100%;
  //         min-height: 380px;
  //         background: url('~@/assets/img/lock/card_bg2.png') no-repeat;
  //         background-size: cover;
  //         position: relative;
  //         margin-top: 20px;
  //         padding: 30px 15px 0;
  //         p {
  //           font-size: 13px;
  //           line-height: 1.5;
  //           color: #9299a9;
  //         }
  //         .h3 {
  //           margin-top: 10px;
  //           line-height: 1.5;
  //           font-size: 15px;
  //           color: #ffffff;
  //         }
  //       }
  //       .title {
  //         position: absolute;
  //         top: 3px;
  //         left: 50%;
  //         transform: translateX(-50%);
  //         font-size: 18px;
  //         font-weight: 600;
  //         color: #131520;
  //       }
  //     }
  //   }
}
// ::v-deep {
//   .van-nav-bar {
//     background: #131520;
//     .van-icon-arrow-left {
//       color: #fff;
//     }
//     .van-nav-bar__title {
//       color: #fff;
//     }
//   }
// }
