
.right {
  font-family: PingFangSC-Regular, PingFang SC;
  color: #00b897;
}

.list {
  padding: 15px;
  .item {
    width: 100%;
    min-height: 70px;
    border-radius: 3px;
    // border-bottom: 1px solid #ebebf0;
    background: #f8f8fb;
    margin-bottom: 10px;
    padding: 10px 15px 10px 10px;
    .row {
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
      .name {
        display: flex;
        align-items: center;
        > span:first-child {
          font-size: 16px;
          font-weight: 600;
          margin-right: 10px;
        }
        > span:last-child {
          flex: 1;
          font-size: 12px;
          color: #00b897;
        }
      }
      .num {
        font-size: 16px;
        color: #191b27;
        position: relative;
        i {
          position: absolute;
          right: -15px;
          top: 5px;
        }
      }
    }
    .row:last-child {
      font-size: 12px;
      color: #9299a9;
    }
  }
}
