
.newOption {
  font-size: 12px;
  .headSelsec {
    display: flex;
    height: 55px;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ebebf0;
    position: relative;
    .selectItem {
      width: 85px;
      height: 30px;
      background: #00b897;
      border-radius: 2px;
      font-size: 14px;
      color: #ffffff;
      font-weight: 600;
      line-height: 30px;
      text-align: center;
    }
    .userCenter {
      position: absolute;
      left: 20px;
      width: 30px;
      height: 30px;
    }
  }
}

.tradingTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding: 0 15px;
  font-size: 16px;
  color: #191b27;
  line-height: 23px;
  margin-top: 15px;
  .tradList {
    width: 17px;
    height: 15px;
  }
  .tradName {
    margin-left: 7px;
  }
  .tradTime {
    color: #9299a9;
    margin-left: 8px;
  }
  .tradbing {
    width: 17px;
    height: 17px;
    position: absolute;
    right: 15px;
  }
}
.tradingTitle2 {
  font-size: 18px;
  justify-content: space-between;
  .tradNum {
    font-size: 25px;
    color: #0AA869;
  }
  .tradName {
    margin-left: 0px;
  }
}
.changeKline {
  display: flex;
  font-size: 14px;
  justify-content: flex-start;
  padding: 0 15px;
  color: #00b897;
  line-height: 20px;
  margin-top: 10px;
  align-items: center;
  margin-bottom: 5px;
  .jiantou {
    width: 10px;
    height: 5px;
    margin-left: 5px;
  }
}
.KlineBox {
  height: 168px;
}
.playArea {
  padding: 0 15px;
  .topContant {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    .selectBox {
      display: flex;
      font-size: 14px;
      .selectItem {
        width: 137px;
        height: 35px;
        font-weight: 400;
        line-height: 35px;
        text-align: center;
        background-size: 100% 100%;
      }

      .duo0 {
        background-image: url('../../assets/img/newOption/duo0.png');
        color: #9299a9;
      }
      .duo1 {
        background-image: url('../../assets/img/newOption/duo1.png');
        color: #ffffff;
      }
      .kong0 {
        background-image: url('../../assets/img/newOption/kong0.png');
        color: #9299a9;
      }
      .kong1 {
        background-image: url('../../assets/img/newOption/kong1.png');
        color: #ffffff;
      }
    }
  }
  .timeBox {
    height: 35px;
    line-height: 35px;
    color: #191b27;
    font-weight: 600;
    font-size: 13px;
    display: flex;
    align-items: center;
    img {
      width: 13px;
      height: 13px;
      margin-right: 3px;
    }
  }
  .inputBox {
    position: relative;
    margin-top: 15px;
    .input {
      height: 40px;
      border-radius: 2px;
      border: 1px solid #c7ccd8;
      line-height: 40px;
      padding: 0 10px;
    }
    .coinName {
      position: absolute;
      right: 10px;
      font-size: 13px;
      color: #9299a9;
      line-height: 40px;
      top: 0;
    }
  }
  .moneyBox {
    display: flex;
    font-size: 12px;
    line-height: 38px;
    justify-content: space-between;
    white-space: nowrap;
    .yue {
      display: flex;
      align-items: center;
      img {
        width: 18px;
        height: 18px;
        margin-left: 10px;
      }
      span {
        color: #5c6573;
      }
    }
    .shouyi {
      color: #ED0071;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .drag {
    position: relative;
    .roundBox {
      display: flex;
      justify-content: space-between;
      .roundItem {
        width: 8px;
        height: 8px;
        background: #c7ccd8;
        border-radius: 50%;
      }
      .round_item_active {
        background: #00b897;
      }
    }
    .roundNumBox {
      display: flex;
      justify-content: space-between;
      font-size: 11px;
      color: #5c6573;
      line-height: 15px;
      margin-top: 9px;

      .roundItem {
        width: 40px;
      }
      .roundItem1 {
        text-align: left;
      }
      .roundItem2 {
        text-align: center;
      }
      .roundItem3 {
        text-align: right;
      }
    }
    .dragLine {
      position: absolute;
      top: 3px;
    }
  }
  .copeBox {
    position: relative;
    font-size: 12px;
    line-height: 17px;
    color: #ffffff;
    margin-top: 12px;
    .copeLineBox {
      height: 17px;
      display: flex;
      .leftBox {
        width: 50%;
        height: 17px;
        background-color: #40CFA6;
        border-radius: 2px 0 0 2px;
        transition: all 1s ease;
      }
      .rightBox {
        flex: 1;
        height: 17px;
        background-color: #ED0072;
        border-radius: 0 2px 2px 0;
      }
    }
    .leftNum {
      position: absolute;
      left: 12px;
      top: 0;
    }
    .rightNum {
      position: absolute;
      right: 12px;
      top: 0;
    }
    .fenge {
      position: absolute;
      top: 0;
      left: 50%;
      width: 5px;
      height: 17px;
      transition: all 1s ease;
    }
  }
  .betBtn {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    line-height: 40px;
    color: #ffffff;
    margin-top: 30px;
  }
  .betBtn0 {
    background: #ED0071;
  }
  .betBtn1 {
    background: #0AA869;
  }
}
.dise {
  height: 8px;
  background: #f8f8fb;
  margin-top: 20px;
}
.logsTabs {
  border-bottom: 1px solid #ebebf0;
  .logItem {
    line-height: 42px;
    color: #191b27;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    .logItemChildBox-left {
      text-align: left;
    }
    .logItemChildBox-right {
      text-align: right;
    }

    .logItemChildTit {
      color: #9299a9;
    }
    .logItemChildBox {
      flex: 1;
      box-sizing: border-box;
      .smallBox1 {
        font-size: 12px;
        color: #191b27;
        line-height: 17px;
        margin-top: 6px;
      }
      .smallBox2 {
        font-size: 10px;
        line-height: 15px;
        color: #9299a9;
      }
    }
    .add {
      color: #0AA869;
    }
    .reduce1 {
      color: #ED0071;
      flex: 1.3;
    }
    .reduce0 {
      color: #40cfa6;
      flex: 1.3;
    }
  }
}
.typeTitle {
  font-size: 22px;
  line-height: 31px;
  font-weight: 600;
  color: #191b27;
  margin-top: 30px;
}
.tradingSmallBox {
  display: flex;
  padding: 0 15px;
  color: #9299a9;
  font-size: 13px;
  justify-content: space-between;
  margin-top: 15px;
  border-bottom: 1px solid #ebebf0;
  padding-bottom: 10px;
}
.tradingListBox {
  padding: 13px 15px;
  .tradingItemBox {
    .tit {
      .text {
        font-size: 16px;
        color: #00b897;
        line-height: 23px;
      }
      .bgImg {
        background: linear-gradient(to right, #00b897, rgba(0, 184, 151, 0.5));
        width: 22px;
        height: 4px;
        margin-top: 4px;
      }
    }
  }
  .tradingItem {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
    .tradingName {
      display: flex;
      .tradingTime {
        color: #9299a9;
        margin-left: 7px;
        margin-bottom: 15px;
      }
    }
  }
}
.planBody {
  // width: 100%;
  // height: 261px;
  box-sizing: border-box;
  padding-top: 15px;
  .content {
    position: relative;
  }
  .content :first-child {
    display: block;
    margin: 0 auto;
  }

  .center_text {
    position: absolute;
    top: 0;
  }
  .showPlanNum {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    font-size: 22px;
    font-weight: 400;
  }
  .resultWait {
    width: 100%;
    position: relative;
    .resultWaitImg {
      width: 130px;
      height: 130px;
      display: block;
      margin: 0 auto;
    }
    .resultWaitText {
      color: #131520;
      font-size: 18px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-weight: 600;
    }
  }
  .resultOver {
    position: relative;
    .resultOverImg {
      display: block;
      width: 182px;
      height: 46px;
      margin: 0 auto;
      margin-top: 10px;
    }
    .resultOverText {
      text-align: center;
      line-height: 45px;
      font-size: 30px;
      font-weight: 600;
      margin-top: 20px;
      span {
        font-size: 16px;
        color: #131520;
      }
    }
    .win {
      color: #01b184;
    }
    .loser {
      color: #fe3f51;
    }
    .over {
      position: absolute;
      width: 100%;
      font-size: 18px;
      font-weight: 600;
      line-height: 38px;
      color: #131520;
      top: -2px;
      text-align: center;
    }
  }
}
.over {
  position: absolute;
  top: 10;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  line-height: 38px;
  color: #131520;
  top: 6px;
  text-align: center;
}
.popupcontant {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
}
.fenxiangBtn {
  display: flex;
  text-align: center;
  margin-top: 26px;
  justify-content: space-between;
  font-size: 18px;
  line-height: 46px;
  .close {
    width: 48%;
    border-radius: 4px;
    height: 46px;
    background-color: #fff;
  }
  .save {
    width: 48%;
    border-radius: 4px;
    height: 46px;
    background-color: #00b897;
    color: #fff;
  }
}

.optionOrderPopupTit {
  font-size: 18px;
  font-weight: 600;
  padding: 0 20px;
}
.orderDataBox {
  box-sizing: border-box;
  padding: 15px 20px;
  margin-top: 20px;
  background: rgba(188, 188, 188, 0.1);
  .dataitem {
    display: flex;
    justify-content: space-between;
    line-height: 34px;
    font-size: 14px;
    // .right {
    // font-weight: 600;
    // }
    .left {
      color: #000;
      font-weight: 450;
    }
  }
  .tips {
    color: #e54c67;
    font-weight: 450;
    font-size: 9px;
    margin-top: -5px;
    margin-bottom: 5px;
  }
}

.exchangePopup {
  .itemBox {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    .item {
      flex: 1;
      text-align: center;
    }
    .item1 {
      text-align: left;
    }
    .item3 {
      text-align: right;
      color: #191b27;
    }
    .itemBox:nth-child(2) {
      .item1 {
        color: #9299a9;
      }
      .item3 {
        color: #9299a9;
      }
    }
  }
  .itemTit {
    font-size: 14px;
    line-height: 40px;
  }
  .title {
    .item {
      font-size: 13px;
      color: #9299a9;
    }
  }
}
.logItem {
  p {
    text-align: center;
  }
  .fenxiang {
    width: 16px;
    height: 16px;
  }
  .money {
    font-size: 30px;
    line-height: 43px;
    color: #0AA869;
    margin-top: 25px;
  }
  .smalltit {
    font-size: 13px;
    color: #9299a9;
    line-height: 19px;
    margin-top: 5px;
    margin-bottom: 20px;
  }
  .logItem {
    display: flex;
    justify-content: space-between;
    color: #5c6573;
    line-height: 34px;
    font-size: 14px;
    padding: 0 15px;
    .results {
      color: #191b27;
    }
  }
}

.pl15 {
  padding-left: 15px;
}

.up {
  color: #01b184 !important;
}
.down {
  color: #fe3f51 !important;
}

::v-deep .van-slider__button {
  background-image: url('../../assets/img/newOption/yuandian.png');
  background-size: 100% 100%;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 50px;
  border-bottom: 1px solid #ebebf0;
}
::v-deep .custom-image .van-empty__image {
  width: 170px;
  height: 105px;
}
::v-deep .van-empty__description {
  color: #c7ccd8;
}
::v-deep .van-cell {
  padding-top: 0;
  padding-bottom: 0;
  height: 42px;
}
// ::v-deep .van-cell__value {
//   display: flex;
//   justify-content: space-between;
// }
::v-deep .van-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}
::v-deep .van-button--disabled {
  background-color: #c7ccd8 !important;
  opacity: 1;
}
::v-deep .van-hairline--bottom::after {
  border: 0 solid #c2c4c8;
  border-bottom-width: 1px;
}
::v-deep .van-nav-bar .van-icon {
  color: #000;
}
::v-deep .van-cell__right-icon {
  line-height: 43px;
  color: #9299a9;
  margin-left: 0;
  font-size: 12px;
}
.Kline {
  position: relative;
  .klinetitle {
    height: 32px;
    position: absolute;
    right: 7px;
    bottom: 125px;
    display: flex;
    align-items: center;
    .dian {
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: radial-gradient(
        circle,
        #00b897 0%,
        rgba(23, 208, 178, 0.99) 50%,
        rgba(23, 208, 178, 0) 100%
      );
      animation: mymove 1s infinite;
    }
    .xian {
      border-top: 1px dashed #ccc;
      flex: 1;
    }
    .title {
      min-width: 60px;
      background: #00b897;
      line-height: 22px;
      color: #fff;
      text-align: center;
      position: relative;
      .jiantou {
        position: absolute;
        display: block;
        border: 11px solid transparent;
        border-right-color: #00b897;
        top: 0;
        left: -22px;
      }
      /*只兼容火狐*/
      @-moz-document url-prefix() {
        .fuzhu {
          display: block;
          width: 1px;
          height: 22px;
          background: #00b897;
          position: absolute;
          left: -1px;
        }
      }

      div {
        width: 100%;
        overflow: hidden;
        color: #131626;
      }
    }
    .titleUP {
      background: #01b184;
    }
    .titleDOWN {
      background: #fe3f51;
    }
  }
}

.klineChangeDiv {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  .top {
    width: 100%;
    height: 230px;
  }
  .content {
    width: 100%;
    padding: 0 15px;
    background-color: #ffffff;
    display: flex;
    font-size: 12px;
    align-content: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 10px;
    .item {
      width: 61px;
      height: 30px;
      background: rgba(146, 153, 169, 0.1);
      border-radius: 4px;
      text-align: center;
      line-height: 30px;
      color: #9299a9;
      margin-top: 10px;
    }
    .active {
      border: 1px solid #00b897;
      background-color: #ffffff;
    }
  }
  .content1 {
    justify-content: flex-start;
    .item {
      margin-right: 10px;
    }
  }
  .bottom {
    // position: absolute;
    // right: 0;
    // top: 0;
    // left: 0;
    // bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    flex-grow: 1;
  }
}
#jg {
  position: absolute;
  width: 80px;
  height: 120px;
  top: 8px;
  right: 0px;
  // background: url(../../../assets/options/jg.png);
  background-size: 100% 100%;
  font-size: 12px;
  .jg_font {
    position: absolute;
    top: -8px;
    left: -210px;
    color: #00b897;
    width: 200px;
    text-align: right;
  }

  .jg_bg {
    width: 83px;
    height: 134px;
    box-sizing: border-box;
    position: relative;
    .yuan {
      width: 15px;
      height: 15px;
      border: 3px solid #00b897;
      box-sizing: border-box;
      position: absolute;
      border-radius: 50%;
      &:nth-child(1) {
        top: -6px;
        left: -9px;
      }
      &:nth-child(2) {
        bottom: -6px;
        left: -9px;
      }
    }
    img {
      height: 100%;
    }
  }
  .jg_foter {
    height: 22px;
    padding: 0 4px;
    text-align: center;
    line-height: 22px;
    background: #00b897;
    position: absolute;
    bottom: -43px;
    color: #fff;
    left: -43%;
  }
}

::v-deep {
  .van-circle__text {
    font-size: 30px;
    font-family: DIN Condensed-Bold, DIN Condensed;
    font-weight: bold;
    color: #000000;
  }
}
.blur {
  filter: blur(2px);
}
