
.tie {
  font-size: 20px;
  margin-left: 14px;
}

.smtie {
  line-height: 30px;
  margin-left: 14px;
}

.content {
  width: 100%;
  height: 100%;
  padding: 65px 10px 0 0;

  .bar {
    width: 100%;
  }

  .listBox {
    margin-top: 22px;
    padding: 0 15px;

    .item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 29px;

      .name {
        font-size: 12px;
        font-weight: 400;
        color: #1a1d26;
        line-height: 17px;
      }

      .collect {
        width: 19px;
        height: 19px;
      }
    }
  }
}
