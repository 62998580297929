
.shareBox{
  position: relative;
  display: inline-block;
  .titleTime{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 13px;
      color: #742F25;
      line-height: 15px;
      text-align: left;
      position: absolute;
      top: 10px;
      left: 15px;
  }
  .title{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 25px;
      color: #742F25;
      line-height: 35px;
      position: absolute;
      top: 70px;
      left: 20px;
      width: 185px;
      height: 70px;
  }
  .box{
      width: 295px;
      height: 280px;
      background: rgba(255,255,255,0.9);
      border-radius: 12px 12px 12px 12px;
      // margin: auto;
      position: absolute;
      top: 170px;
      left: 20px;
      backdrop-filter: blur(5px);
      padding: 20px;
      .b-title{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 15px;
          color: #000000;
          line-height: 18px;
          text-align: center;
          margin-bottom: 15px;
      }
      .b-Yield{
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 30px;
          color: #00B897;
          line-height: 35px;
          text-align: center;
          margin-bottom: 20px;
      }
      .box-content{
          display: flex;
          justify-content: space-between;
          padding: 10px 0;
          .t1{
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #000000;
              line-height: 14px;
              text-align: center;
              margin-bottom: 5px;
          }
          .s1{
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 11px;
              color: #888888;
              line-height: 13px;
              text-align: center;
          }
      }
      .box-bottom{
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          img{
              width: 70px;
              height: 70px;
          }
          canvas{
              width: 70px!important;
              height: 70px!important;
          }
      }
  }
}
.share-page {
  text-align: center;
  padding: 40px 20px 20px 20px;
}
.share-page img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.red{
    color: #e54d68;
  }
  .gre{
    color: #1baf84;
  }
  .footer{
      display: flex;
      justify-content: space-between;
      .save{
          width: 160px;
          height: 38px;
          background: #00b897;
          border-radius: 19px 19px 19px 19px;
          color: #fff;
      }
      .cancel{
          width: 160px;
          height: 38px;
          border-radius: 19px 19px 19px 19px;
          border: 1px solid #D1D4DD;
          color: #888888;
      }
  }
  
