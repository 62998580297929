
.home {
  background-color: #fff;
  padding-top: 40px;
  .topBox {
    background-color: #fff;
    padding: 9px 16px;
    display: flex;
    align-items: center;

    .topBar {
      flex: 1;
      display: flex;
      justify-content: center;
      background-color: #fff;
      border-radius: 2px;
      padding: 1px;


      .barItem {
        border-radius: 2px;
        //flex: 1;
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        color: #888888;
        line-height: 12px;
        padding: 0 20px;
        border: 1px solid #E5E5E5;
        height: 30px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      //
      .barItemActive {
        background-color: #00b897;
        color: #fff;
      }
    }

    .server {
      display: flex;
      align-items: center;
      padding-top: 2px;
      padding-left: 6px;

      .icon_kefu {
        width: 20px;
      }
    }
  }
}

::v-deep .van-nav-bar .van-icon {
  color: #000000;
}
