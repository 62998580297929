
.popup_transfer {
  padding: 0 15px;
  font-size: 14px;
  .title {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
    color: #191b27;
    letter-spacing: 1px;
  }
  .tit {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #191b27;
  }
  .transfer_coin,
  .transfer_num,
  .transfer_direction {
    width: 100%;
    height: 43px;
    line-height: 43px;
    border-radius: 4px;
    overflow: hidden;
    .button {
      width: 53px;
      height: 43px;
      line-height: 43px;
      text-align: center;
      background: #00b897;
      border-radius: 0px 4px 4px 0px;
      font-size: 15px;
      color: #ffffff;
      img {
        width: 100%;
        height: 100%;
        background-color: #fff;
      }
    }
    .transfer_cell {
      flex: 1;
      display: flex;
      align-items: center;
      // justify-content: space-around;
      line-height: 1.4;
      font-size: 12px;
      padding-left: 12px;
      > span {
        margin-right: 4px;
      }
      .fromAccount {
        color: #00b897;
        text-align: center;
      }
      .toAccount {
        text-align: center;
        color: #00b897;
      }
    }
  }
  .transfer_coin {
    width: 342px;
    background: #f6f7fa;
    border-radius: 4px;
    font-size: 18px;
    color: #333333;
    padding: 0 15px;
    display: flex;
    align-items: center;
    img {
      width: 23px;
      height: 23px;
      border-radius: 50%;
      overflow: hidden;
      background: #ccc;
      margin-right: 10px;
    }
  }
  .transfer_direction {
    border: 1px solid #dcdfe6;
    display: flex;
    justify-content: space-between;
  }
  .transfer_num {
    border: 1px solid #dcdfe6;
    .van-field {
      padding: 0;
      height: 100%;
      width: 100%;
      padding: 0 0 0 12px;
    }
  }
  .desc_num {
    height: 30px;
    font-size: 14px;
    color: #999999;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .sub_btn {
    width: 345px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    background: #00b897;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    margin: 0 auto;
  }
}
.icon_close {
  right: 12px;
  top: 12px;
}
