
div {
  font-size: 16px;
}
.title {
  display: flex;
  align-items: center;
  > div:first-child {
    margin-right: 15px;
  }
  .btn {
    //width: 85px;
    padding: 0 4px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 2px;
    font-size: 14px;
    color: #9299a9;
    border: 1px solid #9299a9;
  }
  .active_btn {
    background: #00b897;
    font-weight: 600;
    color: #ffffff;
    border: none;
  }
}
.list {
  height: 100%;
  padding: 10px;
  .item {
    width: 100%;
    min-height: 100px;
    border-radius: 3px;
    border-bottom: 1px solid #ebebf0;
    margin-bottom: 10px;
    padding: 0 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;

      > div {
        font-size: 13px;
      }
      .name {
        display: flex;
        align-items: center;
        > span:first-child {
          font-size: 16px;
          font-weight: 600;
          margin-right: 10px;
        }
        > span:last-child {
          font-size: 13px;
          color: #9299a9;
        }
        > span.LOCK {
          color: #00b897;
        }
      }
      .num {
        color: #18ae83;
      }
      .date {
        color: #9299a9;
      }
      .address {
        flex: 2;
        // width: 260px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .totalMassage {
        margin-left: 10px;
        text-align: right;
      }
    }
    .cancelReason {
      font-size: 12px;
      color: #00b897;
    }
    .button_cancel {
      background: #00b897;
      text-align: center;
      font-size: 14px;
      color: #fff;
      padding: 4px 0;
      border-radius: 2px;
      margin-top: 10px;
    }
  }
}
.van-pull-refresh {
  // height: calc(100% - 50px);
  // overflow-y: scroll;
}
