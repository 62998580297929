
.setPassword {
    font-size: 12px;
}


.codebox {
    position: relative;

    .btn {
        position: absolute;
        right: 10px;
        top: 12px;
        background-color: #F8F8F8;
    }
}

.getmgs {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 14px;
  color: #00b897;
}

::v-deep .van-nav-bar .van-icon {
    color: #000;
}

::v-deep .van-cell {
    background-color: #f8f8f8;
    padding: 10px 10px;
    margin-top: 10px;
    border-radius: 5px;
}

::v-deep .van-button--primary {
    background-color: #f8f8f8;
    border: 0;
  color: #00b897;
}

::v-deep .van-field--error .van-field__control {
    color: #D1D3D4 !important;
}

.content {

    .area {
        background-color: #f8f8f8;
        padding: 5px 5px;
        width: 70px;
        text-align: center;
        border-radius: 20px;
        margin-top: 20px;
    }

    .next {
        width: 100%;
        height: 46px;
      background: #00b897;
        border-radius: 4px;
        text-align: center;
        line-height: 46px;
        font-size: 18px;
        color: #fff;
        margin-top: 50px;
    }
}

::v-deep .van-popup {
    .van-search--show-action {
        padding-right: 10px;
    }

    .van-search__content {
        border-radius: 5px;

        .van-icon {
            color: #8a8a8a;
        }
    }

    .van-search__action {
        color: #8a8a8a;
    }

    .countriesListBox {
        .listItem {
            font-size: 14px;
            line-height: 54px;
            margin: 0 19px;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #e5e5e5;
        }
    }
}
