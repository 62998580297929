
.setExchangePwd {
  // min-height: 100vh;
  // width: 100vw;
  background-color: #f8f8f8;

  .navbar-handle {
    width: 18px;
  }

  .van-hairline--bottom::after {
    border-bottom-width: 0rem;
  }

  .main-content {
    width: 100vw;
    height: 100%;
    // padding: 30px;
    display: flex;
    display: -webkit-flex; /* Safari */
    flex-direction: column;
  }

  .top-functional {
    width: 92vw;
    min-height: 134px;
    margin-left: 4vw;
    margin-top: 10px;
    border-radius: 4px;
    background-color: #ffffff;
  }

  .sec-functional {
    width: 92vw;
    height: 108px;
    margin-left: 4vw;
    border-radius: 4px;
    background-color: #ffffff;
  }

  .item-cell {
    height: 52.5px;
    background-color: transparent;
    padding: 14.5px;

    .receipt-icon {
      width: 14px;
      height: 14px;
      margin-top: 5px;
    }

    .receipt-title {
      color: #d5d5d5;
      font-size: 15px;
      margin-left: 13px;
    }

    .receipt-content {
      color: #d5d5d5;
      font-size: 15px;
      margin-left: 13px;
    }

    .item-title {
      color: #191b27;
    }

    .item-custom-bx {
      position: relative;
      display: inline-block;
      float: right;
      color: #9299a9;
    }

    .ic-notice {
      left: -20px;
      top: 4px;
      width: 16px;
      height: 16px;
      position: absolute;
    }

    .van-cell__value {
      color: #9299a9;
    }
  }

  .item-cell.top-cell {
    height: 70px;
  }

  .tip-txt {
    margin: 12px 15px;
    font-size: 12px;
    color: #5c6573;
  }

  .item-cell::after {
    border-bottom: 1.5px solid #ebebf0;
  }

  .cell-level {
    width: 100%;

    .level-bx {
      width: 100%;
    }

    .level-tit {
      display: inline-block;
      font-size: 15px;
    }

    .level-tip {
      display: inline-block;
      font-size: 12px;
      color: #00b897;
      float: right;
    }

    .level-bar {
      display: inline-block;
      width: 98.33px;
      height: 4px;
      background-color: #d5d5d5;
    }

    .level-bar.selected {
      background-color: #00b897;
    }

    .mg-lft {
      margin-left: 10px;
    }
  }
}

.phone-label {
  font-size: 15px;
  color: #191b27;
}

.btn-vcode {
  font-size: 15px;
  color: #00b897;
}

.van-cell::after {
  background-color: #ebebf0;
}
.box {
  width: 100%;
  display: flex;
}
.btn-commit {
  width: 345px;
  height: 46px;
  margin: 0 auto;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
}

// 提示
.bottom-tip {
  color: #5c6573;
  font-size: 10px;
  padding: 0 15px 0 35px;
}
.tips {
  margin: 15px 0;
  position: relative;
  &::before {
    position: absolute;
    left: 15px;
    top: 0;
    content: '!';
    display: inline-block;
    width: 10px;
    height: 10px;
    color: #e95252;
    border-radius: 50%;
    border: 1.5px solid;
    font-weight: 600;
    margin-right: 5px;
    transform: translateY(1px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
::v-deep {
  .van-cell {
    padding: 10px 0;
  }
  .van-field__button {
    display: flex;
    align-items: center;
    .van-button {
      height: 100%;
      padding: 0;
      border: none;
      color: #00b897;
      background-color: transparent;
    }
    .van-button--disabled {
      color: #00b897;
    }
  }

  .van-field__label {
    color: #191b27;
  }
}
