


.box{
  padding: 10px;
  .van-row{
    margin-bottom: 10px;
    .margin{
      span{
          margin-right: 10px;
        }
    }
    span{
      color: #5C6573;
      word-break: break-all;
    }
    .Sub{
      color: #000;
    }
    .title{
      font-size: 16px;
    }
    .left{text-align: left;}
    .right{text-align: right;}
    .center{text-align: center;}
    .red{
      color: #e54d68;
    }
    .gre{
      color: #1baf84;
    }
  }
}
.titItem {
    font-size: 16px;
    font-weight: 600;
  }
