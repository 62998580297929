
.box{
    padding: 10px;
    .van-row{
      margin-bottom: 10px;
      span{
        color: #5C6573;
      }
      .Sub{
        color: #000;
      }
      .title{
        font-size: 16px;
      }
      .left{text-align: left;}
      .right{text-align: right;}
    }
  }

