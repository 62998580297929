
.container {
  padding: 0 15px;
}
.main {
  .tabs {
    .tabs_item {
      display: flex;
      align-items: center;
      .btn {
        width: 85px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 2px;
        font-size: 14px;
        font-weight: 400;
        color: #9299a9;
        border: 1px solid #9299a9;
        margin-right: 15px;
      }
      .active_btn {
        background: #00b897;
        font-weight: 600;
        color: #ffffff;
        border: none;
      }
    }
  }
  .cell {
    font-size: 14px;
    font-weight: 400;
    .tit {
      font-size: 14px;
      color: #191b27;
      padding: 20px 0 15px;
      font-weight: 600;
      span {
        font-size: 12px;
        color: #9299a9;
      }
    }
    .slot_button {
      display: flex;
      align-items: center;
      img {
        width: 19px;
        height: 19px;
      }
    }
    .button {
      // width: 58px;
      padding: 0 0 0 15px;
      height: 100%;
      text-align: center;
      border-left: 1px solid #ebebf0;
      color: #00b897;
      margin-left: 10px;
    }
    .desc {
      font-size: 12px;
      color: #9299a9;
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      > span {
        > span {
          color: #ff6060;
        }
      }
    }
  }
}
::v-deep .cell .van-field {
  width: 100%;
  height: 43px;
  border-radius: 4px;
  border: 1px solid #c7ccd8;
  color: #9299a9;
  .van-field__control {
    color: #9299a9;
  }
}
