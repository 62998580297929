
.bar {
  height: 50px;
}
::v-deep {
  .van-nav-bar {
    height: 50px;
    line-height: 50px;
    .van-nav-bar__content {
      height: 100%;
    }
    a {
      display: flex;
      align-items: center;
    }
  }
  .van-nav-bar__arrow {
    font-size: 14px;
  }
  .van-icon-arrow-left {
    color: #191b27;
  }
  .van-nav-bar__right {
    // font-size: 14px;
    color: #5c6573;
    font-weight: 400;
    > a {
      color: #5c6573;
    }
  }
  .van-nav-bar__title {
    font-size: 16px;
    > a {
      display: flex;
      align-items: center;
    }
    img {
      width: 10px;
      height: 5px;
      margin-left: 5px;
    }
  }
}
