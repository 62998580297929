
.setPassword {
  font-size: 12px;
  background-color: #f8f8f8;
  .content {
    padding: 12px;
    border-radius: 8px;
    .van-cell-group {
      border-radius: 10px;
    }
  }
  .tips {
    margin-top: 20px;
    color: #5c6573;
  }
  .next {
    width: 345px;
    height: 46px;
    background: #00b897;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    line-height: 46px;
    margin: 0 auto;
    margin-top: 30px;
  }
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}
