
.setPassword {
  font-size: 12px;
  width: 100%;
  height: 100%;
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}

::v-deep .van-loading {
  text-align: center;
  margin-top: 30px;
}

.iframe {
  width: 100%;
  height: calc(100vh - 1.333rem);
}
