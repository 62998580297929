
.ADPage {
  font-size: 12px;
  background-color: #fff;
  height: 98vh;
  overflow-y: scroll;
  padding-bottom: 100px;
}

.contant {
  width: 90%;
  margin: 0 auto;

  table {
    border-collapse: collapse;
  }

  tr,
  td,
  th {
    /*设置边框线  border：边框线大小 边框线样式 边框线的颜色 */
    border: 1px solid #191b27;
  }
}


.contant1 {
  background-color: #F8F8F8;
  border-radius: 6px;
  margin-top: 20px;
  padding: 10px;
}

.get {
  margin: 0 auto;
  text-align: center;
  height: 30px;
  background-color: #00b897;
  width: 80%;
  border-radius: 20px;
  line-height: 30px;
  color: #fff;
  margin-top: 10px;
  position: fixed;
  bottom: 30px;
  left: 10%;
}

::v-deep .van-nav-bar__right .van-nav-bar__text {
  color: #5c6573;
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}
