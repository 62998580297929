

.home{
  height: 100vh;
}
.box{
  padding: 10px;
  .van-row{
    margin-bottom: 10px;
    .margin{
      span{
        margin-right: 10px;
      }
    }
    span{
      color: #5C6573;
    }
    .Sub{
      color: #000;
    }
    .title{
      font-size: 16px;
    }
    .left{text-align: left;}
    .right{text-align: right;}
    .red{
      color: #e54d68;
    }
    .gre{
      color: #1baf84;
    }
  }
}
.titItem {
    font-size: 16px;
    font-weight: 600;
  }
::v-deep .van-nav-bar__right{
  white-space: pre-wrap;
  overflow-wrap: break-word;
  max-width: 100px;
  line-height: 16px;
  text-align: right;
  word-break: break-all;
}
