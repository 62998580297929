
.recharging {
  height: 100%;
  overflow-y: scroll;
}
.nav_bar {
  .left {
    width: 295px;
    height: 38px;
    background: #f8f8fb;
    border-radius: 4px;
    .van-cell {
      padding: 0 10px;
      height: 38px;
      line-height: 38px;
    }
  }
  .icon_search {
    width: 17px;
    height: 17px;
  }
}

.recharging_list {
  padding: 20px 15px 0;
  .recharging_list_item {
    height: 46px;
    line-height: 46px;
    font-size: 14px;
    border-bottom: 1px solid #c7ccd8;
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid #ccc;
      margin-right: 10px;
    }
  }
}
::v-deep .nav_bar .van-cell {
  background: transparent;
  .van-field__left-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .van-icon-clear {
    color: #c7ccd8;
  }
}
